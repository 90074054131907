@import "~react-image-gallery/styles/scss/image-gallery.scss";
$color_1: #ffffff;
$color_2: inherit;
$color_3: #0d6efd;
$color_4: #0a58ca;
$color_5: #d63384;
$color_6: #6c757d;
$color_7: var(--bs-table-striped-color);
$color_8: var(--bs-table-active-color);
$color_9: var(--bs-table-hover-color);
$color_10: #000;
$color_11: transparent;
$color_12: #198754;
$color_13: #dc3545;
$color_14: #0dcaf0;
$color_15: #ffc107;
$color_16: #eaeaea;
$color_17: #686868;
$color_18: #212529;
$color_19: #1e2125;
$color_20: #adb5bd;
$color_21: #dee2e6;
$color_22: #495057;
$color_23: rgba(0, 0, 0, 0.9);
$color_24: rgba(0, 0, 0, 0.55);
$color_25: rgba(0, 0, 0, 0.7);
$color_26: rgba(0, 0, 0, 0.3);
$color_27: rgba(255, 255, 255, 0.55);
$color_28: rgba(255, 255, 255, 0.75);
$color_29: rgba(255, 255, 255, 0.25);
$color_30: #0c63e4;
$color_31: #565e64;
$color_32: #146c43;
$color_33: #3dd5f3;
$color_34: #ffcd39;
$color_35: #b02a37;
$color_36: #eeeeee;
$color_37: #1c2835;
$color_38: rgba(0, 0, 0, 0.5);
$color_39: rgba(255, 255, 255, 0.5);
$color_40: #888888;
$color_41: #555555;
$color_42: #040404;
// $color_43: #3f9457;
$color_43: #f75201;
$color_44: $color_43;
$color_45: #6c6c6c;
$color_46: #111;
$font-family_1: var(--bs-font-sans-serif);
$font-family_2: var(--bs-font-monospace);
$font-family_3: inherit;
$font-family_4: "Rubik", sans-serif;
$font-family_5: sans-serif;
$font-family_6: "Font Awesome 5 Free";
$background-color_1: $color_1;
$background-color_2: currentColor;
$background-color_3: #fcf8e3;
$background-color_4: #212529;
$background-color_5: var(--bs-table-bg);
$background-color_6: #e9ecef;
$background-color_7: #dde0e3;
$background-color_8: transparent;
$background-color_9: #0d6efd;
$background-color_10: #b6d4fe;
$background-color_11: $color_21;
$background-color_12: #adb5bd;
$background-color_13: rgba(25, 135, 84, 0.9);
$background-color_14: #198754;
$background-color_15: rgba(220, 53, 69, 0.9);
$background-color_16: #dc3545;
$background-color_17: #0b5ed7;
$background-color_18: #0a58ca;
$background-color_19: #6c757d;
$background-color_20: #5c636a;
$background-color_21: #565e64;
$background-color_22: #157347;
$background-color_23: #146c43;
$background-color_24: #0dcaf0;
$background-color_25: #31d2f2;
$background-color_26: #3dd5f3;
$background-color_27: #ffc107;
$background-color_28: #ffca2c;
$background-color_29: #ffcd39;
$background-color_30: #bb2d3b;
$background-color_31: #b02a37;
$background-color_32: $color_16;
$background-color_33: #ededed;
$background-color_34: #eeeeee;
$background-color_35: $color_17;
$background-color_36: #1e2b38;
$background-color_37: #1c2835;
$background-color_38: #343a40;
$background-color_39: rgba(255, 255, 255, 0.15);
$background-color_40: rgba(0, 0, 0, 0.03);
$background-color_41: #e7f1ff;
$background-color_42: $color_10;
$background-color_43: $color_43;
// $background-color_43: #3c8d53;
$background-color_44: #993909; 
// $background-color_44: #3f9457; 
$background-color_45: $color_43;
$background-color_46: rgba(255, 255, 255, 0.4);
$background-color_47: rgba(255, 255, 255, 0.2);
$background-color_48: #6ab0de;
$background-color_49: #78c578;
$background-color_50: #f0b37e;
$background-color_51: #e06f6c;
$background-color_52: rgba(#6c6c6c, 0.2);
$background-color_53: rgba($color_43, 0.2);
$background-color_54: rgba(145, 122, 235, 0.1);
$background-color_55: rgba(81, 181, 109, 0.1);
$background-color_56: #fbfbfb;
$border-color_1: inherit;
$border-color_2: #acb9c4;
$border-color_3: #bacbe6;
$border-color_4: #cbccce;
$border-color_5: #bcd0c7;
$border-color_6: #badce3;
$border-color_7: #e6dbb9;
$border-color_8: #dfc2c4;
$border-color_9: lightgray;
$border-color_10: #394755;
$border-color_11: #86b7fe;
$border-color_12: #0d6efd;
$border-color_13: transparent;
$border-color_14: #198754;
$border-color_15: #dc3545;
$border-color_16: #0a58ca;
$border-color_17: #0a53be;
$border-color_18: #6c757d;
$border-color_19: #565e64;
$border-color_20: #51585e;
$border-color_21: #146c43;
$border-color_22: #13653f;
$border-color_23: #0dcaf0;
$border-color_24: #25cff2;
$border-color_25: #ffc107;
$border-color_26: #ffc720;
$border-color_27: #b02a37;
$border-color_28: #a52834;
$border-color_29: $color_16;
$border-color_30: #ececec;
$border-color_31: $color_17;
$border-color_32: #1c2835;
$border-color_33: #1a2632;
$border-color_34: rgba(0, 0, 0, 0.15);
$border-color_35: #e9ecef #e9ecef $color_21;
$border-color_36: $color_21 $color_21 $color_1;
$border-color_37: rgba(0, 0, 0, 0.1);
$border-color_38: rgba(255, 255, 255, 0.1);
$border-color_39: $color_21;
$border-color_40: $color_1;
$border-color_41: $color_43;
$border-color_42: #dfdfdf;
$border-color_43: rgba(81, 181, 109, 0.4);
$border-bottom-color_1: currentColor;

.root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: $color_1;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: $color_16;
  --bs-dark: $color_17;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}
* {
  box-sizing: border-box;
  &::before {
    box-sizing: border-box;
  }
  &::after {
    box-sizing: border-box;
  }
}
body {
  margin: 0;
  font-family: $font-family_1;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color_1;
  background-color: $background-color_1;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1.7;
  font-family: $font-family_4;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  font-weight: 400;
  color: $color_40;
  background-color: $background-color_1;
  overflow-x: hidden;
}
hr {
  margin: 1rem 0;
  color: $color_2;
  background-color: $background-color_2;
  border: 0;
  opacity: 0.25;
  &:not([size]) {
    height: 1px;
  }
}
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1rem;
  color: $color_42;
  font-family: $font-family_4;
  font-weight: 700;
  font-size: 14px;
}
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1rem;
  color: $color_42;
  font-family: $font-family_4;
  font-weight: 700;
  font-size: 14px;
}
h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.25rem;
  color: $color_42;
  font-family: $font-family_4;
  font-weight: 700;
  font-size: 18px;
}
.h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.25rem;
  color: $color_42;
  font-family: $font-family_4;
  font-weight: 700;
  font-size: 18px;
}
h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.275rem + 0.3vw);
  color: $color_42;
  font-family: $font-family_4;
  font-weight: 700;
  font-size: 22px;
}
.h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.275rem + 0.3vw);
  color: $color_42;
  font-family: $font-family_4;
  font-weight: 700;
  font-size: 22px;
}
h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.3rem + 0.6vw);
  color: $color_42;
  font-family: $font-family_4;
  font-weight: 700;
  font-size: 24px;
}
.h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.3rem + 0.6vw);
  color: $color_42;
  font-family: $font-family_4;
  font-weight: 700;
  font-size: 24px;
}
h2 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.325rem + 0.9vw);
  color: $color_42;
  font-family: $font-family_4;
  font-weight: 700;
  font-size: 36px;
}
.h2 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.325rem + 0.9vw);
  color: $color_42;
  font-family: $font-family_4;
  font-weight: 700;
  font-size: 36px;
}
h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.375rem + 1.5vw);
  color: $color_42;
  font-family: $font-family_4;
  font-weight: 700;
  font-size: 44px;
}
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.375rem + 1.5vw);
  color: $color_42;
  font-family: $font-family_4;
  font-weight: 700;
  font-size: 44px;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 16px;
}
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol {
  padding-left: 2rem;
  margin-top: 0;
  margin-bottom: 1rem;
  ol {
    margin-bottom: 0;
  }
  ul {
    margin-bottom: 0;
  }
}
ul {
  padding-left: 2rem;
  margin-top: 0;
  margin-bottom: 1rem;
  ul {
    margin-bottom: 0;
  }
  ol {
    margin-bottom: 0;
  }
}
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
  &::before {
    font-family: $font-family_6;
    font-weight: 900;
    content: "\f10d";
    font-size: 36px;
  }
}
b {
  font-weight: bolder;
}
strong {
  font-weight: bolder;
}
small {
  font-size: 0.875em;
}
.small {
  font-size: 0.875em;
}
mark {
  padding: 0.2em;
  background-color: $background-color_3;
}
.mark {
  padding: 0.2em;
  background-color: $background-color_3;
}
sub {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
  bottom: -0.25em;
}
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
  top: -0.5em;
}
a {
  color: $color_3;
  text-decoration: underline;
  text-decoration: none !important;
  cursor: pointer;
  transition: 0.2s ease;
  color: $color_44;
  &:hover {
    color: $color_4;
    text-decoration: none !important;
    color: $color_44;
    text-decoration: underline;
  }
  &:not([href]) {
    &:not([class]) {
      color: $color_2;
      text-decoration: none;
      &:hover {
        color: $color_2;
        text-decoration: none;
      }
    }
  }
  > code {
    color: $color_2;
  }
  &:focus {
    text-decoration: none !important;
    outline: 0;
  }
}
pre {
  font-family: $font-family_2;
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override;
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  code {
    font-size: inherit;
    color: $color_2;
    word-break: normal;
  }
}
code {
  font-family: $font-family_2;
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override;
  font-size: 0.875em;
  color: $color_5;
  word-wrap: break-word;
}
kbd {
  font-family: $font-family_2;
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override;
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: $color_1;
  background-color: $background-color_4;
  border-radius: 0.2rem;
  kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700;
  }
}
samp {
  font-family: $font-family_2;
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  vertical-align: middle;
  border: 0;
  max-width: 100%;
  height: auto;
}
svg {
  vertical-align: middle;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: $color_6;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
  border-color: $border-color_1;
  border-style: solid;
  border-width: 0;
}
thead {
  border-color: $border-color_1;
  border-style: solid;
  border-width: 0;
}
tbody {
  border-color: $border-color_1;
  border-style: solid;
  border-width: 0;
}
tfoot {
  border-color: $border-color_1;
  border-style: solid;
  border-width: 0;
}
tr {
  border-color: $border-color_1;
  border-style: solid;
  border-width: 0;
}
td {
  border-color: $border-color_1;
  border-style: solid;
  border-width: 0;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
  margin: 0;
  font-family: $font-family_3;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
  transition: 0.2s ease;
  &:focus {
    &:not(:focus-visible) {
      outline: 0;
    }
    outline: 0;
  }
  &:not(:disabled) {
    cursor: pointer;
  }
}
input {
  margin: 0;
  font-family: $font-family_3;
  font-size: inherit;
  line-height: inherit;
}
select {
  margin: 0;
  font-family: $font-family_3;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  word-wrap: normal;
  cursor: pointer;
  transition: 0.2s ease;
  &:disabled {
    opacity: 1;
  }
  &:focus {
    outline: 0;
  }
}
optgroup {
  margin: 0;
  font-family: $font-family_3;
  font-size: inherit;
  line-height: inherit;
}
textarea {
  margin: 0;
  font-family: $font-family_3;
  font-size: inherit;
  line-height: inherit;
  resize: vertical;
}
[role="button"] {
  cursor: pointer;
}
[list] {
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}
[type="button"] {
  -webkit-appearance: button;
  &:not(:disabled) {
    cursor: pointer;
  }
}
[type="reset"] {
  -webkit-appearance: button;
  &:not(:disabled) {
    cursor: pointer;
  }
}
[type="submit"] {
  -webkit-appearance: button;
  &:not(:disabled) {
    cursor: pointer;
  }
}
// &::-moz-focus-inner {
// 	padding: 0;
// 	border-style: none;
// }
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  &:disabled {
    .btn {
      pointer-events: none;
      opacity: 0.65;
    }
  }
}
legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  + {
    * {
      clear: left;
    }
  }
}
// &::-webkit-datetime-edit-fields-wrapper {
// 	padding: 0;
// }
// &::-webkit-datetime-edit-text {
// 	padding: 0;
// }
// &::-webkit-datetime-edit-minute {
// 	padding: 0;
// }
// &::-webkit-datetime-edit-hour-field {
// 	padding: 0;
// }
// &::-webkit-datetime-edit-day-field {
// 	padding: 0;
// }
// &::-webkit-datetime-edit-month-field {
// 	padding: 0;
// }
// &::-webkit-datetime-edit-year-field {
// 	padding: 0;
// }
// &::-webkit-inner-spin-button {
// 	height: auto;
// }
// [type=search] {
// 	outline-offset: -2px;
// 	-webkit-appearance: textfield;
// }
// &::-webkit-search-decoration {
// 	-webkit-appearance: none;
// }
// &::-webkit-color-swatch-wrapper {
// 	padding: 0;
// }
// &::-webkit-file-upload-button {
// 	font: inherit;
// 	font: inherit;
// 	-webkit-appearance: button;
// }
// &::file-selector-button {
// 	font: inherit;
// }
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  display: list-item;
  cursor: pointer;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
  color: $color_41;
}
.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}
.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  //  > {
  // 	&:last-child {
  // 		margin-bottom: 0;
  // 	}
  // }
}
.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: $color_6;
  &::before {
    content: "— ";
  }
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: $background-color_1;
  border: 1px solid $color_21;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 0.875em;
  color: $color_6;
}
.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.container-fluid {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.container-lg {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.container-md {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
}
.col {
  flex: 1 0 0%;
}
.row-cols-auto {
  > * {
    flex: 0 0 auto;
    width: auto;
  }
}
.row-cols-1 {
  > * {
    flex: 0 0 auto;
    width: 100%;
  }
}
.row-cols-2 {
  > * {
    flex: 0 0 auto;
    width: 50%;
  }
}
.row-cols-3 {
  > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}
.row-cols-4 {
  > * {
    flex: 0 0 auto;
    width: 25%;
  }
}
.row-cols-5 {
  > * {
    flex: 0 0 auto;
    width: 20%;
  }
}
.row-cols-6 {
  > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
.g-0 {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}
.gx-0 {
  --bs-gutter-x: 0;
}
.gy-0 {
  --bs-gutter-y: 0;
}
.g-1 {
  --bs-gutter-x: 0.25rem;
  --bs-gutter-y: 0.25rem;
}
.gx-1 {
  --bs-gutter-x: 0.25rem;
}
.gy-1 {
  --bs-gutter-y: 0.25rem;
}
.g-2 {
  --bs-gutter-x: 0.5rem;
  --bs-gutter-y: 0.5rem;
}
.gx-2 {
  --bs-gutter-x: 0.5rem;
}
.gy-2 {
  --bs-gutter-y: 0.5rem;
}
.g-3 {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 1rem;
}
.gx-3 {
  --bs-gutter-x: 1rem;
}
.gy-3 {
  --bs-gutter-y: 1rem;
}
.g-4 {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem;
}
.gx-4 {
  --bs-gutter-x: 1.5rem;
}
.gy-4 {
  --bs-gutter-y: 1.5rem;
}
.g-5 {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 3rem;
}
.gx-5 {
  --bs-gutter-x: 3rem;
}
.gy-5 {
  --bs-gutter-y: 3rem;
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: $color_1;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: $color_1;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: $color_1;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  // color: $color_1;
  vertical-align: top;
  border-color: $border-color_2;
  &:not(caption) {
    > * {
      > * {
        padding: 0.5rem 0.5rem;
        background-color: $background-color_5;
        border-bottom-width: 1px;
        box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
      }
    }
  }
  // &:not(:last-child) {
  // 		&:last-child {
  // 			>* {
  // 				border-bottom-color: $border-bottom-color_1;
  // 			}
  // 	}
  // }
  tbody {
    vertical-align: inherit;
  }
  thead {
    vertical-align: bottom;
  }
}
.caption-top {
  caption-side: top;
}
.table-sm {
  &:not(caption) {
    > * {
      > * {
        padding: 0.25rem 0.25rem;
      }
    }
  }
}
.table-bordered {
  &:not(caption) {
    > * {
      border-width: 1px 0;
      > * {
        border-width: 0 1px;
      }
    }
  }
}
.table-borderless {
  &:not(caption) {
    > * {
      > * {
        border-bottom-width: 0;
      }
    }
  }
}
.table-striped {
  tbody {
    tr {
      &:nth-of-type(odd) {
        --bs-table-accent-bg: var(--bs-table-striped-bg);
        color: $color_7;
      }
    }
  }
}
.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: $color_8;
}
.table-hover {
  tbody {
    tr {
      &:hover {
        --bs-table-accent-bg: var(--bs-table-hover-bg);
        color: $color_9;
      }
    }
  }
}
.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: $color_10;
  --bs-table-active-bg: $border-color_3;
  --bs-table-active-color: $color_10;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: $color_10;
  color: $color_10;
  border-color: $border-color_3;
}
.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: $color_10;
  --bs-table-active-bg: $border-color_4;
  --bs-table-active-color: $color_10;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: $color_10;
  color: $color_10;
  border-color: $border-color_4;
}
.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: $color_10;
  --bs-table-active-bg: $border-color_5;
  --bs-table-active-color: $color_10;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: $color_10;
  color: $color_10;
  border-color: $border-color_5;
}
.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: $color_10;
  --bs-table-active-bg: $border-color_6;
  --bs-table-active-color: $color_10;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: $color_10;
  color: $color_10;
  border-color: $border-color_6;
}
.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: $color_10;
  --bs-table-active-bg: $border-color_7;
  --bs-table-active-color: $color_10;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: $color_10;
  color: $color_10;
  border-color: $border-color_7;
}
.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: $color_10;
  --bs-table-active-bg: $border-color_8;
  --bs-table-active-color: $color_10;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: $color_10;
  color: $color_10;
  border-color: $border-color_8;
}
.table-light {
  --bs-table-bg: $color_16;
  --bs-table-striped-bg: #dedede;
  --bs-table-striped-color: $color_10;
  --bs-table-active-bg: $border-color_9;
  --bs-table-active-color: $color_10;
  --bs-table-hover-bg: #d8d8d8;
  --bs-table-hover-color: $color_10;
  color: $color_10;
  border-color: $border-color_9;
}
.table-dark {
  --bs-table-bg: $color_17;
  --bs-table-striped-bg: #2e3c4b;
  --bs-table-striped-color: $color_1;
  --bs-table-active-bg: $border-color_10;
  --bs-table-active-color: $color_1;
  --bs-table-hover-bg: #344150;
  --bs-table-hover-color: $color_1;
  color: $color_1;
  border-color: $border-color_10;
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.form-label {
  margin-bottom: 0.5rem;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}
.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: $color_6;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color_1;
  background-color: $background-color_1;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: $color_17 !important;
  &:focus {
    color: $color_1;
    background-color: $background-color_1;
    border-color: $border-color_11;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    border-color: $border-color_41;
  }
  &::-webkit-date-and-time-value {
    height: 1.5em;
  }
  &::-moz-placeholder {
    color: $color_6;
    opacity: 1;
    opacity: 0.5;
  }
  &::placeholder {
    color: $color_6;
    opacity: 1;
    opacity: 0.5;
  }
  &:disabled {
    background-color: $background-color_6;
    opacity: 1;
    background-color: $background-color_1;
    cursor: no-drop;
  }
  &::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
    color: $color_1;
    background-color: $background-color_6;
    pointer-events: none;
    border-color: $border-color_1;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
    color: $color_1;
    background-color: $background-color_6;
    pointer-events: none;
    border-color: $border-color_1;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  &::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
    color: $color_1;
    background-color: $background-color_6;
    pointer-events: none;
    border-color: $border-color_1;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  &:hover {
    &:not(:disabled) {
      &:not([readonly]) {
        &::-webkit-file-upload-button {
          background-color: $background-color_7;
          background-color: $background-color_7;
        }
        &::file-selector-button {
          background-color: $background-color_7;
        }
      }
    }
  }
  &:not(textarea) {
    height: 55px;
  }
}
.form-control[type="file"] {
  overflow: hidden;
  &:not(:disabled) {
    &:not([readonly]) {
      cursor: pointer;
    }
  }
}
.form-control[readonly] {
  background-color: $background-color_6;
  opacity: 1;
  background-color: $background-color_1;
  cursor: no-drop;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: $color_1;
  background-color: $background-color_8;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
  &::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    -webkit-margin-end: 0.5rem;
    margin-inline-end: 0.5rem;
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    -webkit-margin-end: 0.5rem;
    margin-inline-end: 0.5rem;
  }
  &::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    -webkit-margin-end: 0.5rem;
    margin-inline-end: 0.5rem;
  }
}
.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
  &::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
  }
  &::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
  }
}
textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px));
  height: 140px;
}
textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}
textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}
.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
  &:not(:disabled) {
    &:not([readonly]) {
      cursor: pointer;
    }
  }
  &::-moz-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem;
  }
  &::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem;
  }
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color_1;
  background-color: $background-color_1;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:focus {
    border-color: $border-color_11;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
  &:disabled {
    background-color: $background-color_6;
  }
  &:-moz-focusring {
    color: $color_11;
    text-shadow: 0 0 0 $color_1;
  }
}
.form-select[multiple] {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select[size] {
  &:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none;
  }
}
.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
  .form-check-input {
    float: left;
    margin-left: -1.5em;
  }
}
.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: $background-color_1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  &:active {
    filter: brightness(90%);
  }
  &:focus {
    border-color: $border-color_11;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
  &:checked {
    background-color: $background-color_9;
    border-color: $border-color_12;
  }
  &:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
  &:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  }
  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
    ~ {
      .form-check-label {
        opacity: 0.5;
      }
    }
  }
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  &:indeterminate {
    background-color: $background-color_9;
    border-color: $border-color_12;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  }
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input[disabled] {
  ~ {
    .form-check-label {
      opacity: 0.5;
    }
  }
}
.form-switch {
  padding-left: 2.5em;
  .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out;
    &:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
    }
    &:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }
  }
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
  .form-check-input {
    ~ {
      .valid-feedback {
        margin-left: 0.5em;
      }
      .invalid-feedback {
        margin-left: 0.5em;
      }
    }
  }
}
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  &:disabled {
    + {
      .btn {
        pointer-events: none;
        filter: none;
        opacity: 0.65;
      }
    }
  }
  &:focus {
    + {
      .btn {
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
      }
      .btn-primary {
        color: $color_1;
        background-color: $background-color_17;
        border-color: $border-color_16;
        box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
      }
      .btn-secondary {
        color: $color_1;
        background-color: $background-color_20;
        border-color: $border-color_19;
        box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
      }
      .btn-success {
        color: $color_1;
        background-color: $background-color_22;
        border-color: $border-color_21;
        box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
      }
      .btn-info {
        color: $color_10;
        background-color: $background-color_25;
        border-color: $border-color_24;
        box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
      }
      .btn-warning {
        color: $color_10;
        background-color: $background-color_28;
        border-color: $border-color_26;
        box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
      }
      .btn-danger {
        color: $color_1;
        background-color: $background-color_30;
        border-color: $border-color_27;
        box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
      }
      .btn-light {
        color: $color_10;
        background-color: $background-color_33;
        border-color: $border-color_30;
        box-shadow: 0 0 0 0.25rem rgba(199, 199, 199, 0.5);
      }
      .btn-dark {
        color: $color_1;
        background-color: $background-color_36;
        border-color: $border-color_32;
        box-shadow: 0 0 0 0.25rem rgba(68, 81, 94, 0.5);
      }
      .btn-outline-primary {
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
      }
      .btn-outline-secondary {
        box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
      }
      .btn-outline-success {
        box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
      }
      .btn-outline-info {
        box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
      }
      .btn-outline-warning {
        box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
      }
      .btn-outline-danger {
        box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
      }
      .btn-outline-light {
        box-shadow: 0 0 0 0.25rem rgba(234, 234, 234, 0.5);
      }
      .btn-outline-dark {
        box-shadow: 0 0 0 0.25rem rgba(35, 50, 66, 0.5);
      }
    }
  }
  &:checked {
    + {
      .btn-primary {
        color: $color_1;
        background-color: $background-color_18;
        border-color: $border-color_17;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
        }
      }
      .btn-secondary {
        color: $color_1;
        background-color: $background-color_21;
        border-color: $border-color_20;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
        }
      }
      .btn-success {
        color: $color_1;
        background-color: $background-color_23;
        border-color: $border-color_22;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
        }
      }
      .btn-info {
        color: $color_10;
        background-color: $background-color_26;
        border-color: $border-color_24;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
        }
      }
      .btn-warning {
        color: $color_10;
        background-color: $background-color_29;
        border-color: $border-color_26;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
        }
      }
      .btn-danger {
        color: $color_1;
        background-color: $background-color_31;
        border-color: $border-color_28;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
        }
      }
      .btn-light {
        color: $color_10;
        background-color: $background-color_34;
        border-color: $border-color_30;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(199, 199, 199, 0.5);
        }
      }
      .btn-dark {
        color: $color_1;
        background-color: $background-color_37;
        border-color: $border-color_33;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(68, 81, 94, 0.5);
        }
      }
      .btn-outline-primary {
        color: $color_1;
        background-color: $background-color_9;
        border-color: $border-color_12;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
        }
      }
      .btn-outline-secondary {
        color: $color_1;
        background-color: $background-color_19;
        border-color: $border-color_18;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
        }
      }
      .btn-outline-success {
        color: $color_1;
        background-color: $background-color_14;
        border-color: $border-color_14;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
        }
      }
      .btn-outline-info {
        color: $color_10;
        background-color: $background-color_24;
        border-color: $border-color_23;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
        }
      }
      .btn-outline-warning {
        color: $color_10;
        background-color: $background-color_27;
        border-color: $border-color_25;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
        }
      }
      .btn-outline-danger {
        color: $color_1;
        background-color: $background-color_16;
        border-color: $border-color_15;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
        }
      }
      .btn-outline-light {
        color: $color_10;
        background-color: $background-color_32;
        border-color: $border-color_29;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(234, 234, 234, 0.5);
        }
      }
      .btn-outline-dark {
        color: $color_1;
        background-color: $background-color_35;
        border-color: $border-color_31;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(35, 50, 66, 0.5);
        }
      }
    }
  }
  &:active {
    + {
      .btn-primary {
        color: $color_1;
        background-color: $background-color_18;
        border-color: $border-color_17;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
        }
      }
      .btn-secondary {
        color: $color_1;
        background-color: $background-color_21;
        border-color: $border-color_20;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
        }
      }
      .btn-success {
        color: $color_1;
        background-color: $background-color_23;
        border-color: $border-color_22;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
        }
      }
      .btn-info {
        color: $color_10;
        background-color: $background-color_26;
        border-color: $border-color_24;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
        }
      }
      .btn-warning {
        color: $color_10;
        background-color: $background-color_29;
        border-color: $border-color_26;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
        }
      }
      .btn-danger {
        color: $color_1;
        background-color: $background-color_31;
        border-color: $border-color_28;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
        }
      }
      .btn-light {
        color: $color_10;
        background-color: $background-color_34;
        border-color: $border-color_30;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(199, 199, 199, 0.5);
        }
      }
      .btn-dark {
        color: $color_1;
        background-color: $background-color_37;
        border-color: $border-color_33;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(68, 81, 94, 0.5);
        }
      }
      .btn-outline-primary {
        color: $color_1;
        background-color: $background-color_9;
        border-color: $border-color_12;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
        }
      }
      .btn-outline-secondary {
        color: $color_1;
        background-color: $background-color_19;
        border-color: $border-color_18;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
        }
      }
      .btn-outline-success {
        color: $color_1;
        background-color: $background-color_14;
        border-color: $border-color_14;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
        }
      }
      .btn-outline-info {
        color: $color_10;
        background-color: $background-color_24;
        border-color: $border-color_23;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
        }
      }
      .btn-outline-warning {
        color: $color_10;
        background-color: $background-color_27;
        border-color: $border-color_25;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
        }
      }
      .btn-outline-danger {
        color: $color_1;
        background-color: $background-color_16;
        border-color: $border-color_15;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
        }
      }
      .btn-outline-light {
        color: $color_10;
        background-color: $background-color_32;
        border-color: $border-color_29;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(234, 234, 234, 0.5);
        }
      }
      .btn-outline-dark {
        color: $color_1;
        background-color: $background-color_35;
        border-color: $border-color_31;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(35, 50, 66, 0.5);
        }
      }
    }
  }
}
.btn-check[disabled] {
  + {
    .btn {
      pointer-events: none;
      filter: none;
      opacity: 0.65;
    }
  }
}
.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: $background-color_8;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:focus {
    outline: 0;
    &::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px $color_1, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
    &::-moz-range-thumb {
      box-shadow: 0 0 0 1px $color_1, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
  }
  &::-moz-focus-outer {
    border: 0;
  }
  &::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: $background-color_9;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    &:active {
      background-color: $background-color_10;
    }
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: $color_11;
    cursor: pointer;
    background-color: $background-color_11;
    border-color: $border-color_13;
    border-radius: 1rem;
  }
  &::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: $background-color_9;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
    &:active {
      background-color: $background-color_10;
    }
  }
  &::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: $color_11;
    cursor: pointer;
    background-color: $background-color_11;
    border-color: $border-color_13;
    border-radius: 1rem;
  }
  &:disabled {
    pointer-events: none;
    &::-webkit-slider-thumb {
      background-color: $background-color_12;
    }
    &::-moz-range-thumb {
      background-color: $background-color_12;
    }
  }
}
.form-floating {
  position: relative;
  > .form-control {
    height: calc(3.5rem + 2px);
    line-height: 1.25;
    padding: 1rem 0.75rem;
    &::-moz-placeholder {
      color: $color_11;
    }
    &::placeholder {
      color: $color_11;
    }
    &:not(:-moz-placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
      ~ {
        label {
          opacity: 0.65;
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
        }
      }
    }
    &:focus {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
      ~ {
        label {
          opacity: 0.65;
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
        }
      }
    }
    &:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
      ~ {
        label {
          opacity: 0.65;
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
        }
      }
    }
    &:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
      ~ {
        label {
          opacity: 0.65;
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
        }
      }
    }
  }
  > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25;
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
    ~ {
      label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      }
    }
  }
   label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
   .form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    // &:focus {
    //   z-index: 3;
    // }
  }
   .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    &:focus {
      z-index: 3;
    }
  }
  .btn {
    position: relative;
    z-index: 2;
    &:focus {
      z-index: 3;
    }
  }
  &:not(.has-validation) {
      &:not(:last-child) {
        &:not(.dropdown-toggle) {
          &:not(.dropdown-menu) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
     .dropdown-toggle {
      &:nth-last-child(n + 3) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
//   > {
//     &:not(:first-child) {
//       &:not(.dropdown-menu) {
//         &:not(.valid-tooltip) {
//           &:not(.valid-feedback) {
//             &:not(.invalid-tooltip) {
//               &:not(.invalid-feedback) {
//                 margin-left: -1px;
//                 border-top-left-radius: 0;
//                 border-bottom-left-radius: 0;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
  .form-control.is-valid {
    z-index: 1;
    &:focus {
      z-index: 3;
    }
  }
  .form-select.is-valid {
    z-index: 1;
    &:focus {
      z-index: 3;
    }
  }
  .form-control.is-invalid {
    z-index: 2;
    &:focus {
      z-index: 3;
    }
  }
  .form-select.is-invalid {
    z-index: 2;
    &:focus {
      z-index: 3;
    }
  }
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color_1;
  text-align: center;
  white-space: nowrap;
  background-color: $background-color_6;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-lg {
   .form-control {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
  }
   .form-select {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
    padding-right: 3rem;
  }
   .input-group-text {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
  }
   .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
  }
}
.input-group-sm {
   .form-control {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
  }
   .form-select {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
    padding-right: 3rem;
  }
   .input-group-text {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
  }
   .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
  }
}
.input-group.has-validation {
    &:nth-last-child(n + 3) {
      &:not(.dropdown-toggle) {
        &:not(.dropdown-menu) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
  }
   .dropdown-toggle {
    &:nth-last-child(n + 4) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: $color_12;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: $color_1;
  background-color: $background-color_13;
  border-radius: 0.25rem;
}
.was-validated {
  &:valid {
    ~ {
      .valid-feedback {
        display: block;
      }
      .valid-tooltip {
        display: block;
      }
    }
  }
  .form-control {
    &:valid {
      border-color: $border-color_14;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      &:focus {
        border-color: $border-color_14;
        box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
      }
    }
    &:invalid {
      border-color: $border-color_15;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      &:focus {
        border-color: $border-color_15;
        box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
      }
    }
  }
  textarea.form-control {
    &:valid {
      padding-right: calc(1.5em + 0.75rem);
      background-position: top calc(0.375em + 0.1875rem) right
        calc(0.375em + 0.1875rem);
    }
    &:invalid {
      padding-right: calc(1.5em + 0.75rem);
      background-position: top calc(0.375em + 0.1875rem) right
        calc(0.375em + 0.1875rem);
    }
  }
  .form-select {
    &:valid {
      border-color: $border-color_14;
      &:not([multiple]) {
        &:not([size]) {
          padding-right: 4.125rem;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
          background-position: right 0.75rem center, center right 2.25rem;
          background-size: 16px 12px,
            calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }
      }
      &:not([multiple])[size="1"] {
        padding-right: 4.125rem;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
          url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-position: right 0.75rem center, center right 2.25rem;
        background-size: 16px 12px,
          calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }
      &:focus {
        border-color: $border-color_14;
        box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
      }
    }
    &:invalid {
      border-color: $border-color_15;
      &:not([multiple]) {
        &:not([size]) {
          padding-right: 4.125rem;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
          background-position: right 0.75rem center, center right 2.25rem;
          background-size: 16px 12px,
            calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }
      }
      &:not([multiple])[size="1"] {
        padding-right: 4.125rem;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
          url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-position: right 0.75rem center, center right 2.25rem;
        background-size: 16px 12px,
          calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }
      &:focus {
        border-color: $border-color_15;
        box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
      }
    }
  }
  .form-check-input {
    &:valid {
      border-color: $border-color_14;
      &:checked {
        background-color: $background-color_14;
      }
      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
      }
      ~ {
        .form-check-label {
          color: $color_12;
        }
      }
    }
    &:invalid {
      border-color: $border-color_15;
      &:checked {
        background-color: $background-color_16;
      }
      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
      }
      ~ {
        .form-check-label {
          color: $color_13;
        }
      }
    }
  }
  .input-group {
    .form-control {
      &:valid {
        z-index: 1;
        &:focus {
          z-index: 3;
        }
      }
      &:invalid {
        z-index: 2;
        &:focus {
          z-index: 3;
        }
      }
    }
    .form-select {
      &:valid {
        z-index: 1;
        &:focus {
          z-index: 3;
        }
      }
      &:invalid {
        z-index: 2;
        &:focus {
          z-index: 3;
        }
      }
    }
  }
  &:invalid {
    ~ {
      .invalid-feedback {
        display: block;
      }
      .invalid-tooltip {
        display: block;
      }
    }
  }
}
.is-valid {
  ~ {
    .valid-feedback {
      display: block;
    }
    .valid-tooltip {
      display: block;
    }
  }
}
.form-control.is-valid {
  border-color: $border-color_14;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  &:focus {
    border-color: $border-color_14;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
  }
}
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.form-select.is-valid {
  border-color: $border-color_14;
  &:not([multiple]) {
    &:not([size]) {
      padding-right: 4.125rem;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-position: right 0.75rem center, center right 2.25rem;
      background-size: 16px 12px,
        calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
  &:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  &:focus {
    border-color: $border-color_14;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
  }
}
.form-check-input.is-valid {
  border-color: $border-color_14;
  &:checked {
    background-color: $background-color_14;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
  }
  ~ {
    .form-check-label {
      color: $color_12;
    }
  }
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: $color_13;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: $color_1;
  background-color: $background-color_15;
  border-radius: 0.25rem;
}
.is-invalid {
  ~ {
    .invalid-feedback {
      display: block;
    }
    .invalid-tooltip {
      display: block;
    }
  }
}
.form-control.is-invalid {
  border-color: $border-color_15;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  &:focus {
    border-color: $border-color_15;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
}
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.form-select.is-invalid {
  border-color: $border-color_15;
  &:not([multiple]) {
    &:not([size]) {
      padding-right: 4.125rem;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-position: right 0.75rem center, center right 2.25rem;
      background-size: 16px 12px,
        calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
  &:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  &:focus {
    border-color: $border-color_15;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
}
.form-check-input.is-invalid {
  border-color: $border-color_15;
  &:checked {
    background-color: $background-color_16;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  ~ {
    .form-check-label {
      color: $color_13;
    }
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: $color_1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: $background-color_8;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 15px;
  text-transform: capitalize;
  padding: 13px 28px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  font-weight: 500;
  transition: 0.2s ease;
  overflow: hidden;
  border: 0;
  &:hover {
    color: $color_1;
    outline: 0;
    box-shadow: none !important;
    text-decoration: none;
    &::after {
      top: -75px;
      left: -55px;
      height: 300px;
      width: 300px;
      transition: 0.4s;
      transform: rotate(0deg);
    }
  }
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    outline: 0;
    box-shadow: none !important;
    text-decoration: none;
    &::after {
      top: -75px;
      left: -55px;
      height: 300px;
      width: 300px;
      transition: 0.4s;
      transform: rotate(0deg);
    }
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.65;
  }
  &::after {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    background-color: $background-color_43;
    top: 100%;
    left: 60%;
    z-index: -1;
    border-radius: 50%;
    transition: 0.3s;
    transform: rotate(25deg);
  }
  &:active {
    outline: 0;
    box-shadow: none !important;
    text-decoration: none;
    &::after {
      top: -75px;
      left: -55px;
      height: 300px;
      width: 300px;
      transition: 0.4s;
      transform: rotate(0deg);
    }
  }
}
.btn.disabled {
  pointer-events: none;
  opacity: 0.65;
}
.btn-primary {
  color: $color_1;
  background-color: $background-color_9;
  border-color: $border-color_12;
  background: $color_43;
  color: $color_1;
  border-color: $border-color_41;
  &:hover {
    color: $color_1;
    background-color: $background-color_17;
    border-color: $border-color_16;
    background: $color_43 !important;
    color: $color_1;
  }
  &:focus {
    color: $color_1;
    background-color: $background-color_17;
    border-color: $border-color_16;
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
    background: $color_43 !important;
    color: $color_1;
  }
  &:active {
    color: $color_1;
    background-color: $background-color_18;
    border-color: $border-color_17;
    background: $color_43 !important;
    color: $color_1;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
    }
  }
  &:disabled {
    color: $color_1;
    background-color: $background-color_9;
    border-color: $border-color_12;
  }
}
.btn-primary.active {
  color: $color_1;
  background-color: $background-color_18;
  border-color: $border-color_17;
  background: $color_43 !important;
  color: $color_1;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
  }
}
.show {
  > .btn-primary.dropdown-toggle {
    color: $color_1;
    background-color: $background-color_18;
    border-color: $border-color_17;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
    }
  }
  > .btn-secondary.dropdown-toggle {
    color: $color_1;
    background-color: $background-color_21;
    border-color: $border-color_20;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
    }
  }
  > .btn-success.dropdown-toggle {
    color: $color_1;
    background-color: $background-color_23;
    border-color: $border-color_22;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
    }
  }
  > .btn-info.dropdown-toggle {
    color: $color_10;
    background-color: $background-color_26;
    border-color: $border-color_24;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
    }
  }
  > .btn-warning.dropdown-toggle {
    color: $color_10;
    background-color: $background-color_29;
    border-color: $border-color_26;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
    }
  }
  > .btn-danger.dropdown-toggle {
    color: $color_1;
    background-color: $background-color_31;
    border-color: $border-color_28;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
    }
  }
  > .btn-light.dropdown-toggle {
    color: $color_10;
    background-color: $background-color_34;
    border-color: $border-color_30;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(199, 199, 199, 0.5);
    }
  }
  > .btn-dark.dropdown-toggle {
    color: $color_1;
    background-color: $background-color_37;
    border-color: $border-color_33;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(68, 81, 94, 0.5);
    }
  }
}
.btn-primary.disabled {
  color: $color_1;
  background-color: $background-color_9;
  border-color: $border-color_12;
}
.btn-secondary {
  color: $color_1;
  background-color: $background-color_19;
  border-color: $border-color_18;
  &:hover {
    color: $color_1;
    background-color: $background-color_20;
    border-color: $border-color_19;
  }
  &:focus {
    color: $color_1;
    background-color: $background-color_20;
    border-color: $border-color_19;
    box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
  }
  &:active {
    color: $color_1;
    background-color: $background-color_21;
    border-color: $border-color_20;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
    }
  }
  &:disabled {
    color: $color_1;
    background-color: $background-color_19;
    border-color: $border-color_18;
  }
}
.btn-secondary.active {
  color: $color_1;
  background-color: $background-color_21;
  border-color: $border-color_20;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
  }
}
.btn-secondary.disabled {
  color: $color_1;
  background-color: $background-color_19;
  border-color: $border-color_18;
}
.btn-success {
  color: $color_1;
  background-color: $background-color_14;
  border-color: $border-color_14;
  &:hover {
    color: $color_1;
    background-color: $background-color_22;
    border-color: $border-color_21;
  }
  &:focus {
    color: $color_1;
    background-color: $background-color_22;
    border-color: $border-color_21;
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
  }
  &:active {
    color: $color_1;
    background-color: $background-color_23;
    border-color: $border-color_22;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
    }
  }
  &:disabled {
    color: $color_1;
    background-color: $background-color_14;
    border-color: $border-color_14;
  }
}
.btn-success.active {
  color: $color_1;
  background-color: $background-color_23;
  border-color: $border-color_22;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
  }
}
.btn-success.disabled {
  color: $color_1;
  background-color: $background-color_14;
  border-color: $border-color_14;
}
.btn-info {
  color: $color_10;
  background-color: $background-color_24;
  border-color: $border-color_23;
  &:hover {
    color: $color_10;
    background-color: $background-color_25;
    border-color: $border-color_24;
  }
  &:focus {
    color: $color_10;
    background-color: $background-color_25;
    border-color: $border-color_24;
    box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
  }
  &:active {
    color: $color_10;
    background-color: $background-color_26;
    border-color: $border-color_24;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
    }
  }
  &:disabled {
    color: $color_10;
    background-color: $background-color_24;
    border-color: $border-color_23;
  }
}
.btn-info.active {
  color: $color_10;
  background-color: $background-color_26;
  border-color: $border-color_24;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
  }
}
.btn-info.disabled {
  color: $color_10;
  background-color: $background-color_24;
  border-color: $border-color_23;
}
.btn-warning {
  color: $color_10;
  background-color: $background-color_27;
  border-color: $border-color_25;
  &:hover {
    color: $color_10;
    background-color: $background-color_28;
    border-color: $border-color_26;
  }
  &:focus {
    color: $color_10;
    background-color: $background-color_28;
    border-color: $border-color_26;
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
  }
  &:active {
    color: $color_10;
    background-color: $background-color_29;
    border-color: $border-color_26;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
    }
  }
  &:disabled {
    color: $color_10;
    background-color: $background-color_27;
    border-color: $border-color_25;
  }
}
.btn-warning.active {
  color: $color_10;
  background-color: $background-color_29;
  border-color: $border-color_26;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
  }
}
.btn-warning.disabled {
  color: $color_10;
  background-color: $background-color_27;
  border-color: $border-color_25;
}
.btn-danger {
  color: $color_1;
  background-color: $background-color_16;
  border-color: $border-color_15;
  &:hover {
    color: $color_1;
    background-color: $background-color_30;
    border-color: $border-color_27;
  }
  &:focus {
    color: $color_1;
    background-color: $background-color_30;
    border-color: $border-color_27;
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
  }
  &:active {
    color: $color_1;
    background-color: $background-color_31;
    border-color: $border-color_28;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
    }
  }
  &:disabled {
    color: $color_1;
    background-color: $background-color_16;
    border-color: $border-color_15;
  }
}
.btn-danger.active {
  color: $color_1;
  background-color: $background-color_31;
  border-color: $border-color_28;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
  }
}
.btn-danger.disabled {
  color: $color_1;
  background-color: $background-color_16;
  border-color: $border-color_15;
}
.btn-light {
  color: $color_10;
  background-color: $background-color_32;
  border-color: $border-color_29;
  &:hover {
    color: $color_10;
    background-color: $background-color_33;
    border-color: $border-color_30;
  }
  &:focus {
    color: $color_10;
    background-color: $background-color_33;
    border-color: $border-color_30;
    box-shadow: 0 0 0 0.25rem rgba(199, 199, 199, 0.5);
  }
  &:active {
    color: $color_10;
    background-color: $background-color_34;
    border-color: $border-color_30;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(199, 199, 199, 0.5);
    }
  }
  &:disabled {
    color: $color_10;
    background-color: $background-color_32;
    border-color: $border-color_29;
  }
}
.btn-light.active {
  color: $color_10;
  background-color: $background-color_34;
  border-color: $border-color_30;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(199, 199, 199, 0.5);
  }
}
.btn-light.disabled {
  color: $color_10;
  background-color: $background-color_32;
  border-color: $border-color_29;
}
.btn-dark {
  color: $color_1;
  background-color: $background-color_35;
  border-color: $border-color_31;
  &:hover {
    color: $color_1;
    background-color: $background-color_36;
    border-color: $border-color_32;
  }
  &:focus {
    color: $color_1;
    background-color: $background-color_36;
    border-color: $border-color_32;
    box-shadow: 0 0 0 0.25rem rgba(68, 81, 94, 0.5);
  }
  &:active {
    color: $color_1;
    background-color: $background-color_37;
    border-color: $border-color_33;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(68, 81, 94, 0.5);
    }
  }
  &:disabled {
    color: $color_1;
    background-color: $background-color_35;
    border-color: $border-color_31;
  }
}
.btn-dark.active {
  color: $color_1;
  background-color: $background-color_37;
  border-color: $border-color_33;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(68, 81, 94, 0.5);
  }
}
.btn-dark.disabled {
  color: $color_1;
  background-color: $background-color_35;
  border-color: $border-color_31;
}
.btn-outline-primary {
  color: $color_3;
  border-color: $border-color_12;
  background: #f2e7e0;
  color: $color_43;


  &:hover {
    color: $color_1;
    background-color: $background-color_9;
    border-color: $border-color_12;
    background: #f2e7e0;
    color: $color_1;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
    background: #f2e7e0;
    color: $color_1;
  }
  &:active {
    color: $color_1;
    background-color: $background-color_9;
    border-color: $border-color_12;
    background: #f2e7e0;
    color: $color_1;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
    }
  }
  &:disabled {
    color: $color_3;
    background-color: $background-color_8;
  }
  &::after {
    background-color: $background-color_45;
  }
}
.btn-outline-primary.active {
  color: $color_1;
  background-color: $background-color_9;
  border-color: $border-color_12;
  background: #f2e7e0;
  color: $color_1;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
  }
}
.btn-outline-primary.dropdown-toggle.show {
  color: $color_1;
  background-color: $background-color_9;
  border-color: $border-color_12;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
  }
}
.btn-outline-primary.disabled {
  color: $color_3;
  background-color: $background-color_8;
}
.btn-outline-secondary {
  color: $color_6;
  border-color: $border-color_18;
  &:hover {
    color: $color_1;
    background-color: $background-color_19;
    border-color: $border-color_18;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
  }
  &:active {
    color: $color_1;
    background-color: $background-color_19;
    border-color: $border-color_18;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
    }
  }
  &:disabled {
    color: $color_6;
    background-color: $background-color_8;
  }
}
.btn-outline-secondary.active {
  color: $color_1;
  background-color: $background-color_19;
  border-color: $border-color_18;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
  }
}
.btn-outline-secondary.dropdown-toggle.show {
  color: $color_1;
  background-color: $background-color_19;
  border-color: $border-color_18;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
  }
}
.btn-outline-secondary.disabled {
  color: $color_6;
  background-color: $background-color_8;
}
.btn-outline-success {
  color: $color_12;
  border-color: $border-color_14;
  &:hover {
    color: $color_1;
    background-color: $background-color_14;
    border-color: $border-color_14;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
  }
  &:active {
    color: $color_1;
    background-color: $background-color_14;
    border-color: $border-color_14;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
    }
  }
  &:disabled {
    color: $color_12;
    background-color: $background-color_8;
  }
}
.btn-outline-success.active {
  color: $color_1;
  background-color: $background-color_14;
  border-color: $border-color_14;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
  }
}
.btn-outline-success.dropdown-toggle.show {
  color: $color_1;
  background-color: $background-color_14;
  border-color: $border-color_14;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
  }
}
.btn-outline-success.disabled {
  color: $color_12;
  background-color: $background-color_8;
}
.btn-outline-info {
  color: $color_14;
  border-color: $border-color_23;
  &:hover {
    color: $color_10;
    background-color: $background-color_24;
    border-color: $border-color_23;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
  }
  &:active {
    color: $color_10;
    background-color: $background-color_24;
    border-color: $border-color_23;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
    }
  }
  &:disabled {
    color: $color_14;
    background-color: $background-color_8;
  }
}
.btn-outline-info.active {
  color: $color_10;
  background-color: $background-color_24;
  border-color: $border-color_23;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
  }
}
.btn-outline-info.dropdown-toggle.show {
  color: $color_10;
  background-color: $background-color_24;
  border-color: $border-color_23;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
  }
}
.btn-outline-info.disabled {
  color: $color_14;
  background-color: $background-color_8;
}
.btn-outline-warning {
  color: $color_15;
  border-color: $border-color_25;
  &:hover {
    color: $color_10;
    background-color: $background-color_27;
    border-color: $border-color_25;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
  }
  &:active {
    color: $color_10;
    background-color: $background-color_27;
    border-color: $border-color_25;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
    }
  }
  &:disabled {
    color: $color_15;
    background-color: $background-color_8;
  }
}
.btn-outline-warning.active {
  color: $color_10;
  background-color: $background-color_27;
  border-color: $border-color_25;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
  }
}
.btn-outline-warning.dropdown-toggle.show {
  color: $color_10;
  background-color: $background-color_27;
  border-color: $border-color_25;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
  }
}
.btn-outline-warning.disabled {
  color: $color_15;
  background-color: $background-color_8;
}
.btn-outline-danger {
  color: $color_13;
  border-color: $border-color_15;
  &:hover {
    color: $color_1;
    background-color: $background-color_16;
    border-color: $border-color_15;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
  }
  &:active {
    color: $color_1;
    background-color: $background-color_16;
    border-color: $border-color_15;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
    }
  }
  &:disabled {
    color: $color_13;
    background-color: $background-color_8;
  }
}
.btn-outline-danger.active {
  color: $color_1;
  background-color: $background-color_16;
  border-color: $border-color_15;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
  }
}
.btn-outline-danger.dropdown-toggle.show {
  color: $color_1;
  background-color: $background-color_16;
  border-color: $border-color_15;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
  }
}
.btn-outline-danger.disabled {
  color: $color_13;
  background-color: $background-color_8;
}
.btn-outline-light {
  color: $color_16;
  border-color: $border-color_29;
  &:hover {
    color: $color_10;
    background-color: $background-color_32;
    border-color: $border-color_29;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(234, 234, 234, 0.5);
  }
  &:active {
    color: $color_10;
    background-color: $background-color_32;
    border-color: $border-color_29;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(234, 234, 234, 0.5);
    }
  }
  &:disabled {
    color: $color_16;
    background-color: $background-color_8;
  }
}
.btn-outline-light.active {
  color: $color_10;
  background-color: $background-color_32;
  border-color: $border-color_29;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(234, 234, 234, 0.5);
  }
}
.btn-outline-light.dropdown-toggle.show {
  color: $color_10;
  background-color: $background-color_32;
  border-color: $border-color_29;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(234, 234, 234, 0.5);
  }
}
.btn-outline-light.disabled {
  color: $color_16;
  background-color: $background-color_8;
}
.btn-outline-dark {
  color: $color_17;
  border-color: $border-color_31;
  &:hover {
    color: $color_1;
    background-color: $background-color_35;
    border-color: $border-color_31;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(35, 50, 66, 0.5);
  }
  &:active {
    color: $color_1;
    background-color: $background-color_35;
    border-color: $border-color_31;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(35, 50, 66, 0.5);
    }
  }
  &:disabled {
    color: $color_17;
    background-color: $background-color_8;
  }
}
.btn-outline-dark.active {
  color: $color_1;
  background-color: $background-color_35;
  border-color: $border-color_31;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(35, 50, 66, 0.5);
  }
}
.btn-outline-dark.dropdown-toggle.show {
  color: $color_1;
  background-color: $background-color_35;
  border-color: $border-color_31;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(35, 50, 66, 0.5);
  }
}
.btn-outline-dark.disabled {
  color: $color_17;
  background-color: $background-color_8;
}
.btn-link {
  font-weight: 400;
  color: $color_3;
  text-decoration: underline;
  &:hover {
    color: $color_4;
  }
  &:disabled {
    color: $color_6;
  }
}
.btn-link.disabled {
  color: $color_6;
}
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
  padding: 13px 25px;
}
.fade {
  transition: opacity 0.15s linear;
  &:not(.show) {
    opacity: 0;
  }
}
.collapse {
  &:not(.show) {
    display: none;
  }
}
.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.dropup {
  position: relative;
  .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
  .dropdown-toggle {
    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0;
      border-right: 0.3em solid transparent;
      border-bottom: 0.3em solid;
      border-left: 0.3em solid transparent;
    }
    &:empty {
      &::after {
        margin-left: 0;
      }
    }
  }
}
.dropend {
  position: relative;
  .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }
  .dropdown-toggle {
    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0;
      border-bottom: 0.3em solid transparent;
      border-left: 0.3em solid;
      vertical-align: 0;
    }
    &:empty {
      &::after {
        margin-left: 0;
      }
    }
  }
}
.dropdown {
  position: relative;
}
.dropstart {
  position: relative;
  .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }
  .dropdown-toggle {
    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      display: none;
    }
    &::before {
      display: inline-block;
      margin-right: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0.3em solid;
      border-bottom: 0.3em solid transparent;
      vertical-align: 0;
    }
    &:empty {
      &::after {
        margin-left: 0;
      }
    }
  }
}
.dropdown-toggle {
  white-space: nowrap;
  &::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  &:empty {
    &::after {
      margin-left: 0;
    }
  }
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: $color_1;
  text-align: left;
  list-style: none;
  background-color: $background-color_1;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}
.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: $color_18;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: $background-color_8;
  border: 0;
  &:hover {
    color: $color_19;
    background-color: $background-color_6;
  }
  &:focus {
    color: $color_19;
    background-color: $background-color_6;
  }
  &:active {
    color: $color_1;
    text-decoration: none;
    background-color: $background-color_9;
  }
  &:disabled {
    color: $color_20;
    pointer-events: none;
    background-color: $background-color_8;
  }
}
.dropdown-item.active {
  color: $color_1;
  text-decoration: none;
  background-color: $background-color_9;
}
.dropdown-item.disabled {
  color: $color_20;
  pointer-events: none;
  background-color: $background-color_8;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: $color_6;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: $color_18;
}
.dropdown-menu-dark {
  color: $color_21;
  background-color: $background-color_38;
  border-color: $border-color_34;
  .dropdown-item {
    color: $color_21;
    &:hover {
      color: $color_1;
      background-color: $background-color_39;
    }
    &:focus {
      color: $color_1;
      background-color: $background-color_39;
    }
    &:active {
      color: $color_1;
      background-color: $background-color_9;
    }
    &:disabled {
      color: $color_20;
    }
  }
  .dropdown-item.active {
    color: $color_1;
    background-color: $background-color_9;
  }
  .dropdown-item.disabled {
    color: $color_20;
  }
  .dropdown-divider {
    border-color: $border-color_34;
  }
  .dropdown-item-text {
    color: $color_21;
  }
  .dropdown-header {
    color: $color_20;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: $color_3;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  &:hover {
    color: $color_4;
  }
  &:focus {
    color: $color_4;
  }
}
.nav-link.disabled {
  color: $color_6;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid $color_21;
  .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    &:hover {
      border-color: $border-color_35;
      isolation: isolate;
    }
    &:focus {
      border-color: $border-color_35;
      isolation: isolate;
    }
  }
  .nav-link.disabled {
    color: $color_6;
    background-color: $background-color_8;
    border-color: $border-color_13;
  }
  .nav-link.active {
    color: $color_22;
    background-color: $background-color_1;
    border-color: $border-color_36;
  }
  .nav-item.show {
    .nav-link {
      color: $color_22;
      background-color: $background-color_45;
      border-color: $border-color_36;
    }
  }
  .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.nav-pills {
  .nav-link {
    background: none;
    border: 0;
    border-radius: 0.25rem;
    &:hover {
      color: $color_43 !important;
    }
  }
  .nav-link.active {
    color: $color_1;
    background-color: $background-color_45;
  }
  .show {
    > .nav-link {
      color: $color_1;
      background-color: $background-color_45;
    }
  }
}
.nav-fill {
  > .nav-link {
    flex: 1 1 auto;
    text-align: center;
  }
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
    .nav-link {
      width: 100%;
    }
  }
}
.nav-justified {
  > .nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    .nav-link {
      width: 100%;
    }
  }
}
.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  > .container {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
  }
  > .container-fluid {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
  }
  > .container-sm {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
  }
  > .container-md {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
  }
  > .container-lg {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
  }
  > .container-xl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
  }
  > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
  }
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  font-family: $font-family_4;
  font-size: 30px;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .dropdown-menu {
    position: static;
  }
}
.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: $background-color_8;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem;
  }
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
  .navbar-nav {
    flex-direction: row;
    .dropdown-menu {
      position: absolute;
    }
    .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
  .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-toggler {
    display: none;
  }
}
.navbar-light {
  .navbar-brand {
    color: $color_23;
    &:hover {
      color: $color_23;
    }
    &:focus {
      color: $color_23;
    }
  }
  .navbar-nav {
    .nav-link {
      color: $color_24;
      &:hover {
        color: $color_25;
      }
      &:focus {
        color: $color_25;
      }
    }
    .nav-link.disabled {
      color: $color_26;
    }
    .show {
      > .nav-link {
        color: $color_23;
      }
    }
    .nav-link.active {
      color: $color_23;
    }
  }
  .navbar-toggler {
    color: $color_24;
    border-color: $border-color_37;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-text {
    color: $color_24;
    a {
      color: $color_23;
      &:hover {
        color: $color_23;
      }
      &:focus {
        color: $color_23;
      }
    }
  }
}
.navbar-dark {
  .navbar-brand {
    color: $color_1;
    &:hover {
      color: $color_1;
    }
    &:focus {
      color: $color_1;
    }
  }
  .navbar-nav {
    .nav-link {
      color: $color_27;
      &:hover {
        color: $color_28;
      }
      &:focus {
        color: $color_28;
      }
    }
    .nav-link.disabled {
      color: $color_29;
    }
    .show {
      > .nav-link {
        color: $color_1;
      }
    }
    .nav-link.active {
      color: $color_1;
    }
  }
  .navbar-toggler {
    color: $color_27;
    border-color: $border-color_38;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-text {
    color: $color_27;
    a {
      color: $color_1;
      &:hover {
        color: $color_1;
      }
      &:focus {
        color: $color_1;
      }
    }
  }
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $background-color_1;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  > hr {
    margin-right: 0;
    margin-left: 0;
  }
  > .list-group {
    border-top: inherit;
    border-bottom: inherit;
    &:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px);
    }
    &:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px);
    }
    + {
      .card-footer {
        border-top: 0;
      }
    }
  }
  > .card-header {
    + {
      .list-group {
        border-top: 0;
      }
    }
  }
  &:hover {
    .card-social {
      bottom: 20px;
    }
  }
}
.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
.card-title {
  margin-bottom: 0.5rem;
}
.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}
.card-text {
  &:last-child {
    margin-bottom: 0;
  }
}
.card-link {
  &:hover {
    text-decoration: none;
  }
  + {
    .card-link {
      margin-left: 1rem;
    }
  }
}
.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: $background-color_40;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  &:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
}
.card-footer {
  padding: 0.5rem 1rem;
  background-color: $background-color_40;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  &:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  }
}
.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}
.card-img {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
  position: relative;
  overflow: hidden;
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-group {
  > .card {
    margin-bottom: 0.75rem;
  }
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  // color: $color_1;
  text-align: left;
  // background-color: $background-color_1;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  padding: 0.8rem 1.25rem;
  &:not(.collapsed) {
    color: $color_30;
    background-color: $background-color_41;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-180deg);
    }
  }
  &::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
  }
  &:hover {
    z-index: 2;
  }
  &:focus {
    z-index: 3;
    border-color: $border-color_11;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    border-color: $border-color_42;
    box-shadow: none;
  }
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item {
  background-color: $background-color_1;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border: 0;
  &:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    .accordion-button {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px);
    }
  }
  &:not(:first-of-type) {
    border-top: 0;
  }
  &:last-of-type {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    .accordion-button.collapsed {
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px);
    }
    .accordion-collapse {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }
  .accordion-button {
    margin-bottom: 0;
    &:not(.collapsed) {
      color: $color_42;
      background-color: $background-color_8;
      box-shadow: none;
    }
    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
      transform: rotateX(0);
    }
  }
  &:last-child {
    margin-bottom: 0 !important;
  }
  .accordion-button.active {
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 16 16%22 fill=%22%23000%22%3E%3Cpath fill-rule=%22evenodd%22 d=%22M1.646 4.646a.5.5.0 01.708.0L8 10.293l5.646-5.647a.5.5.0 01.708.708l-6 6a.5.5.0 01-.708.0l-6-6a.5.5.0 010-.708z%22/%3E%3C/svg%3E") !important;
      transform: rotateX(180deg);
    }
  }
}
.accordion-body {
  padding: 1rem 1.25rem;
}
.accordion-flush {
  .accordion-collapse {
    border-width: 0;
  }
  .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    &:first-child {
      border-top: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
    .accordion-button {
      border-radius: 0;
    }
  }
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}
.breadcrumb-item {
  + {
    .breadcrumb-item {
      padding-left: 0.5rem;
      &::before {
        float: left;
        padding-right: 0.5rem;
        color: $color_6;
        content: var(--bs-breadcrumb-divider, "/");
      }
    }
  }
}
.breadcrumb-item.active {
  color: $color_6;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  .page-item {
    .page-link {
      display: inline-block;
      width: 48px;
      height: 48px;
      line-height: 48px;
      border-radius: 8px;
      background: transparent;
      text-align: center;
      padding: 0;
      margin-right: 10px;
      border: 1px solid $color_43;
      color: $color_44;
      &:hover {
        background: $color_43;
        color: $color_1;
        border-color: $border-color_41;
        text-decoration: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
    &:first-child {
      .page-link {
        border-radius: 8px;
      }
    }
    &:last-child {
      .page-link {
        border-radius: 8px;
      }
    }
  }
  .page-item.active {
    .page-link {
      background: $color_43;
      color: $color_1;
      border-color: $border-color_41;
    }
  }
}
.page-link {
  position: relative;
  display: block;
  color: $color_3;
  text-decoration: none;
  background-color: $background-color_1;
  border: 1px solid $color_21;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
  &:hover {
    z-index: 2;
    color: $color_4;
    background-color: $background-color_6;
    border-color: $border-color_39;
  }
  &:focus {
    z-index: 3;
    color: $color_4;
    background-color: $background-color_6;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
}
.page-item {
  &:not(:first-child) {
    .page-link {
      margin-left: -1px;
    }
  }
  &:first-child {
    .page-link {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }
  &:last-child {
    .page-link {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
}
.page-item.active {
  .page-link {
    z-index: 3;
    color: $color_1;
    background-color: $background-color_9;
    border-color: $border-color_12;
  }
}
.page-item.disabled {
  .page-link {
    color: $color_6;
    pointer-events: none;
    background-color: $background-color_1;
    border-color: $border-color_39;
  }
}
.pagination-lg {
  .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
  }
  .page-item {
    &:first-child {
      .page-link {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
      }
    }
    &:last-child {
      .page-link {
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
      }
    }
  }
}
.pagination-sm {
  .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
  .page-item {
    &:first-child {
      .page-link {
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
      }
    }
    &:last-child {
      .page-link {
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }
    }
  }
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: $color_10;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  &:hover {
    color: $color_10;
    text-decoration: none;
    opacity: 0.75;
  }
  &:focus {
    outline: 0;
    opacity: 1;
  }
  &:disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    opacity: 0.25;
  }
}
.btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}
.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
  max-width: 550px;
}
.modal.fade {
  .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
  }
}
.modal.show {
  .modal-dialog {
    transform: none;
  }
}
.modal.modal-static {
  .modal-dialog {
    transform: scale(1.02);
  }
}
.modal-dialog-scrollable {
  height: calc(100% - 1rem);
  .modal-content {
    max-height: 100%;
    overflow: hidden;
  }
  .modal-body {
    overflow-y: auto;
  }
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: $background-color_1;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: $background-color_42;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #acb9c4;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
  }
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #acb9c4;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  > * {
    margin: 0.25rem;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-header {
    border-radius: 0;
  }
  .modal-body {
    overflow-y: auto;
  }
  .modal-footer {
    border-radius: 0;
  }
}
.clearfix {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.link-primary {
  color: $color_3;
  &:hover {
    color: $color_4;
  }
  &:focus {
    color: $color_4;
  }
}
.link-secondary {
  color: $color_6;
  &:hover {
    color: $color_31;
  }
  &:focus {
    color: $color_31;
  }
}
.link-success {
  color: $color_12;
  &:hover {
    color: $color_32;
  }
  &:focus {
    color: $color_32;
  }
}
.link-info {
  color: $color_14;
  &:hover {
    color: $color_33;
  }
  &:focus {
    color: $color_33;
  }
}
.link-warning {
  color: $color_15;
  &:hover {
    color: $color_34;
  }
  &:focus {
    color: $color_34;
  }
}
.link-danger {
  color: $color_13;
  &:hover {
    color: $color_35;
  }
  &:focus {
    color: $color_35;
  }
}
.link-light {
  color: $color_16;
  &:hover {
    color: $color_36;
  }
  &:focus {
    color: $color_36;
  }
}
.link-dark {
  color: $color_17;
  &:hover {
    color: $color_37;
  }
  &:focus {
    color: $color_37;
  }
}
.ratio {
  position: relative;
  width: 100%;
  &::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: "";
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}
.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}
.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}
.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden-focusable {
  &:not(:focus) {
    &:not(:focus-within) {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
    }
  }
}
.stretched-link {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
  }
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.float-start {
  float: left !important;
}
.float-end {
  float: right !important;
}
.float-none {
  float: none !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px 25px 65px 0px rgba(0, 0, 0, 0.05) !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.top-0 {
  top: 0 !important;
}
.top-50 {
  top: 50% !important;
}
.top-100 {
  top: 100% !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bottom-50 {
  bottom: 50% !important;
}
.bottom-100 {
  bottom: 100% !important;
}
.start-0 {
  left: 0 !important;
}
.start-50 {
  left: 50% !important;
}
.start-100 {
  left: 100% !important;
}
.end-0 {
  right: 0 !important;
}
.end-50 {
  right: 50% !important;
}
.end-100 {
  right: 100% !important;
}
.translate-middle {
  transform: translate(-50%, -50%) !important;
}
.translate-middle-x {
  transform: translateX(-50%) !important;
}
.translate-middle-y {
  transform: translateY(-50%) !important;
}
.border {
  border: 1px solid #acb9c4 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top {
  border-top: 1px solid #acb9c4 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-end {
  border-right: 1px solid #acb9c4 !important;
}
.border-end-0 {
  border-right: 0 !important;
}
.border-bottom {
  border-bottom: 1px solid #acb9c4 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-start {
  border-left: 1px solid #acb9c4 !important;
}
.border-start-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: $border-color_12 !important;
}
.border-secondary {
  border-color: $border-color_18 !important;
}
.border-success {
  border-color: $border-color_14 !important;
}
.border-info {
  border-color: $border-color_23 !important;
}
.border-warning {
  border-color: $border-color_25 !important;
}
.border-danger {
  border-color: $border-color_15 !important;
}
.border-light {
  border-color: $border-color_29 !important;
}
.border-dark {
  border-color: $border-color_31 !important;
}
.border-white {
  border-color: $border-color_40 !important;
}
.border-1 {
  border-width: 1px !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-3 {
  border-width: 3px !important;
}
.border-4 {
  border-width: 4px !important;
}
.border-5 {
  border-width: 5px !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.gap-3 {
  gap: 1rem !important;
}
.gap-4 {
  gap: 1.5rem !important;
}
.gap-5 {
  gap: 3rem !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.order-first {
  order: -1 !important;
}
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-last {
  order: 6 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.me-3 {
  margin-right: 1rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}
.me-5 {
  margin-right: 3rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.ms-5 {
  margin-left: 3rem !important;
}
.ms-auto {
  margin-left: auto !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.25rem !important;
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.pe-3 {
  padding-right: 1rem !important;
}
.pe-4 {
  padding-right: 1.5rem !important;
}
.pe-5 {
  padding-right: 3rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.25rem !important;
}
.ps-2 {
  padding-left: 0.5rem !important;
}
.ps-3 {
  padding-left: 1rem !important;
}
.ps-4 {
  padding-left: 1.5rem !important;
}
.ps-5 {
  padding-left: 3rem !important;
}
.font-monospace {
  font-family: $font-family_2 !important;
}
.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}
.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}
.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
.fs-5 {
  font-size: 1.25rem !important;
}
.fs-6 {
  font-size: 1rem !important;
}
.fst-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.25 !important;
}
.lh-base {
  line-height: 1.5 !important;
}
.lh-lg {
  line-height: 2 !important;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-primary {
  color: $color_3 !important;
  color: $color_44 !important;
}
.text-secondary {
  color: $color_6 !important;
}
.text-success {
  color: $color_12 !important;
}
.text-info {
  color: $color_14 !important;
}
.text-warning {
  color: $color_15 !important;
}
.text-danger {
  color: $color_13 !important;
}
.text-light {
  color: $color_16 !important;
}
.text-dark {
  color: $color_17 !important;
}
.text-white {
  color: $color_1 !important;
}
.text-body {
  color: $color_1 !important;
}
.text-muted {
  color: $color_6 !important;
}
.text-black-50 {
  color: $color_38 !important;
}
.text-white-50 {
  color: $color_39 !important;
}
.text-reset {
  color: $color_2 !important;
}
.bg-primary {
  background-color: $background-color_9 !important;
  background: $color_43 !important;
}
.bg-secondary {
  background-color: $background-color_19 !important;
  background: #6c6c6c !important;
}
.bg-success {
  background-color: $background-color_14 !important;
}
.bg-info {
  background-color: $background-color_24 !important;
}
.bg-warning {
  background-color: $background-color_27 !important;
}
.bg-danger {
  background-color: $background-color_16 !important;
}
.bg-light {
  background-color: $background-color_32 !important;
}
.bg-dark {
  background-color: $background-color_35 !important;
  background: $color_17 !important;
}
.bg-body {
  background-color: $background-color_1 !important;
}
.bg-white {
  background-color: $background-color_1 !important;
}
.bg-transparent {
  background-color: $background-color_8 !important;
}
.bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}
.pe-none {
  pointer-events: none !important;
}
.pe-auto {
  pointer-events: auto !important;
}
.rounded {
  border-radius: 0.25rem !important;
  border-radius: 8px !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: 0.2rem !important;
}
.rounded-2 {
  border-radius: 0.25rem !important;
}
.rounded-3 {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
.paragraph {
  font-size: 16px;
}
.btn.w-100 {
  &::after {
    display: none;
  }
}
.btn.d-block {
  &::after {
    display: none;
  }
}
.btn.d-flex {
  &::after {
    display: none;
  }
}
.btn.effect-none {
  &::after {
    display: none;
  }
}
.btn.active {
  outline: 0;
  box-shadow: none !important;
  text-decoration: none;
  &::after {
    top: -75px;
    left: -55px;
    height: 300px;
    width: 300px;
    transition: 0.4s;
    transform: rotate(0deg);
  }
}
.btn.focus {
  outline: 0;
  box-shadow: none !important;
  text-decoration: none;
  &::after {
    top: -75px;
    left: -55px;
    height: 300px;
    width: 300px;
    transition: 0.4s;
    transform: rotate(0deg);
  }
}
.btn-primary.focus {
  background: $color_43 !important;
  color: $color_1;
}
.btn-primary.w-100 {
  &:hover {
    background-color: $background-color_44 !important;
  }
}
.btn-primary.d-block {
  &:hover {
    background-color: $background-color_44 !important;
  }
}
.btn-primary.d-flex {
  &:hover {
    background-color: $background-color_44 !important;
  }
}
.btn-primary.effect-none {
  &:hover {
    background-color: $background-color_44 !important;
  }
}
.btn-outline-primary.focus {
  background: #f2e7e0;
  color: $color_1;
}
.btn-outline-primary.w-100 {
  &:hover {
    background: #f2e7e0;
  }
}
.btn-outline-primary.d-block {
  &:hover {
    background: #f2e7e0;
  }
}
.btn-outline-primary.d-flex {
  &:hover {
    background: #f2e7e0;
  }
}
.btn-outline-primary.effect-none {
  &:hover {
    
    background: #f2e7e0;
  }
}
-moz-selection {
  background: $color_43;
  color: $color_1;
}
.selection {
  background: $color_43;
  color: $color_1;
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $background-color_1;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slick-slide {
  outline: 0;
}
.section {
  padding-top: 120px;
  padding-bottom: 120px;
}
.section-sm {
  padding-top: 100px;
  padding-bottom: 100px;
}
.section-title {
  margin-bottom: 30px;
}
.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.outline-0 {
  outline: 0 !important;
}
.bg-primary-light {
  background: white !important;
}
.bg-secondary-light {
  background: white !important;
}
.bg-tertiary {
  background: #fbfbfb !important;
}
.text-black {
  color: $color_42 !important;
  a {
    &:hover {
      color: $color_44 !important;
    }
  }
}
a.text-black {
  &:hover {
    color: $color_44 !important;
  }
}
.text-default {
  color: $color_40 !important;
}
.font-secondary {
  font-family: $font-family_5 !important;
}
.shadow-md {
  box-shadow: 0px 25px 65px 0px rgba(0, 0, 0, 0.1) !important;
}
.difference-of-us-item {
  &:hover {
    box-shadow: 0px 25px 65px 0px rgba(0, 0, 0, 0.1) !important;
  }
  position: relative;
  transition: 0.3s;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .icon {
    height: 85px;
    flex: 0 0 auto;
    width: 85px;
    line-height: 85px;
    text-align: center;
    border-radius: 8px;
    color: $color_45;
    background-color: $background-color_54;
  }
  &:nth-child(even) {
    .icon {
      color: $color_44;
      background-color: $background-color_55;
    }
  }
}
.service-item {
  &:hover {
    box-shadow: 0px 25px 65px 0px rgba(0, 0, 0, 0.1) !important;
    border-color: $border-color_13;
  }
  border-top: 1px solid #f4f4f4;
  border-right: 1px solid #f4f4f4;
  transition: 0.3s;
  position: relative;
  z-index: 10;
  .colored-box {
    display: inline-block;
    height: 70px;
    width: 70px;
    line-height: 70px;
    border-radius: 8px;
    color: $color_44;
    background-color: $background-color_52;
  }
  .service-title {
    transition: color 0.3s;
  }
  .service-description {
    color: $color_40;
  }
  a {
    &:hover {
      .service-title {
        color: $color_44;
      }
      .service-description {
        color: $color_40;
      }
    }
  }
  .block {
    padding: 35px 39px;
  }
  &:nth-child(even) {
    .colored-box {
      color: $color_45;
      background-color: $background-color_53;
    }
  }
}
.rounded-lg {
  border-radius: 15px !important;
}
.content {
  * {
    word-break: break-word;
    overflow-wrap: break-word;
  }
  img {
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 35px;
  }
  h1 {
    font-weight: 700;
    color: $color_17;
    color: $color_17;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 700;
  }
  .h1 {
    font-weight: 700;
    color: $color_17;
    color: $color_17;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 700;
  }
  h2 {
    font-weight: 700;
    color: $color_17;
    color: $color_17;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 700;
  }
  .h2 {
    font-weight: 700;
    color: $color_17;
    color: $color_17;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 700;
  }
  h3 {
    font-weight: 700;
    color: $color_17;
    color: $color_17;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 700;
  }
  .h3 {
    font-weight: 700;
    color: $color_17;
    color: $color_17;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 700;
  }
  h4 {
    font-weight: 700;
    color: $color_17;
    color: $color_17;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 700;
  }
  .h4 {
    font-weight: 700;
    color: $color_17;
    color: $color_17;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 700;
  }
  h5 {
    font-weight: 700;
    color: $color_17;
    color: $color_17;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 700;
  }
  .h5 {
    font-weight: 700;
    color: $color_17;
    color: $color_17;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 700;
  }
  h6 {
    font-weight: 700;
    color: $color_17;
    color: $color_17;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 700;
  }
  .h6 {
    font-weight: 700;
    color: $color_17;
    color: $color_17;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 700;
  }
  strong {
    font-weight: 800;
  }
  a {
    border-bottom: 1px dotted $color_43;
    &:hover {
      color: $color_44;
      border-bottom: 1px solid $color_43;
    }
  }
  ol {
    margin-bottom: 25px;
    padding-left: 0;
    list-style-position: inside;
    li {
      margin-bottom: 10px;
    }
  }
  ul {
    margin-bottom: 25px;
    padding-left: 0;
    list-style-position: inside;
    margin-bottom: 20px;
    list-style-type: none;
    li {
      margin-bottom: 10px;
      padding-left: 0;
      position: relative;
      &::before {
        margin-right: 10px;
        font-size: 13px;
      }
    }
    &:not(.nav) {
      li {
        &::before {
          font-family: $font-family_6;
          font-weight: 900;
          content: "\f192";
          position: absolute;
          left: 0;
          top: 2px;
          color: $color_44;
        }
        padding-left: 20px;
        position: relative;
      }
    }
  }
  p {
    margin-bottom: 16px;
  }
  blockquote {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px 20px;
    p {
      margin-bottom: 0;
      cite {
        display: inline-block;
        margin-top: 10px;
      }
    }
  }
  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border: 1px solid #e0e0e0;
    background-color: $background-color_46;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    td {
      padding: 0.6rem 1rem;
      vertical-align: top;
      margin-bottom: 0;
      border: 1px solid #e0e0e0;
    }
    th {
      padding: 0.6rem 1rem;
      vertical-align: top;
      margin-bottom: 0;
      border: 1px solid #e0e0e0;
    }
  }
  tr {
    &:not(:last-child) {
      border-bottom: 1px solid #e0e0e0;
    }
  }
  th {
    font-weight: 500;
  }
  thead {
    margin-bottom: 0;
    background-color: $background-color_47;
    tr {
      border-bottom: 1px solid #e0e0e0;
    }
  }
  tbody {
    margin-bottom: 0;
  }
  .notices {
    margin: 2rem 0;
    position: relative;
    overflow: hidden;
    padding-top: 30px;
    z-index: 1;
    &::before {
      position: absolute;
      content: "";
      height: 30px;
      width: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    p {
      padding: 10px;
      margin-bottom: 0;
      border: 1px solid #ddd;
      border-top: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      &::after {
        position: absolute;
        top: 2px;
        color: $color_1;
        left: 2rem;
      }
    }
  }
  .notices.note {
    &::before {
      background-color: $background-color_48;
    }
    p {
      &::after {
        content: "Note";
      }
    }
  }
  .notices.tip {
    &::before {
      background-color: $background-color_49;
    }
    p {
      &::after {
        content: "Tip";
      }
    }
  }
  .notices.info {
    &::before {
      background-color: $background-color_50;
    }
    p {
      &::after {
        content: "Info";
      }
    }
  }
  .notices.warning {
    &::before {
      background-color: $background-color_51;
    }
    p {
      &::after {
        content: "Warning";
      }
    }
  }
  pre {
    display: block;
    padding: 10px 15px;
    margin: 10px 0px 10px;
    white-space: pre-wrap;
  }
  code {
    margin-bottom: 0 !important;
    font-size: 100%;
  }
  hr {
    margin: 30px 0;
  }
}

.content.check-list {
  ul {
    li {
      &::before {
        content: "\f00c";
        font-weight: 900;
        font-family: $font-family_6;
        background-color: $background-color_8;
        height: unset;
        width: unset;
        color: $color_44;
        top: 0;
        left: 0;
      }
    }
  }
}
.code-tabs {
  overflow: hidden;
  margin-bottom: 20px;
  .nav-tabs {
    margin-bottom: 0;
    border-bottom: 0;
  }
  .nav-item {
    padding-left: 0;
    .nav-link {
      text-decoration: none;
      font-weight: 500;
      border: 0;
      border-radius: 8px;
      text-transform: capitalize;
      color: $color_44;
    }
  }
  .nav-item.active {
    .nav-link {
      color: $color_1;
      background: $color_43;
    }
  }
  .tab-content {
    padding: 20px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
}
.card-social {
  margin-bottom: 0;
  background-color: $background-color_1;
  box-shadow: 0px 25px 65px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: inline-block;
  padding: 12px 15px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
  transition: 0.2s ease;
  white-space: nowrap;
  a {
    height: 35px;
    width: 35px;
    line-height: 35px;
    background-color: $background-color_1;
    color: $color_17;
    border: 1px solid #ddd;
    display: block;
    border-radius: 6px;
    font-size: 15px;
    &:hover {
      color: $color_44;
    }
  }
}
.notices {
  p {
    i {
      position: absolute;
      top: 7px;
      left: 10px;
      color: $color_1;
    }
    &::before {
      font-family: $font-family_6;
      font-weight: 900;
      content: "\f05a";
      position: absolute;
      top: 2px;
      left: 9px;
      color: $color_1;
    }
  }
}
.check-list {
  ul {
    li {
      &::before {
        font-family: $font-family_6;
        font-weight: 900;
        content: "\f054";
      }
    }
  }
}
.navigation {
  .dropdown-menu {
    border: 0;
    padding: 12px 20px;
  }
  .dropdown-item {
    padding: 6px 0;
    font-weight: 500;
    &:active {
      color: $color_44;
      background-color: $background-color_8;
    }
    &:focus {
      color: $color_44;
      background-color: $background-color_8;
    }
    &:hover {
      color: $color_44;
      background-color: $background-color_8;
    }
  }
  .dropdown-item.active {
    color: $color_44;
    background-color: $background-color_8;
  }
  .nav-item {
    margin: 0;
    .nav-link {
      font-weight: 500;
      text-transform: capitalize;
      position: relative;
      z-index: 1;
      select {
        font-weight: 500;
        font-size: 15px;
      }
      &::before {
        position: absolute;
        content: "";
        bottom: 10px;
        left: 15px;
        width: calc(100% - 30px);
        height: 2px;
        z-index: -1;
        background-color: $background-color_45;
        transform: scaleX(0);
        transition: 0.25s;
      }
    }
    &:hover {
      .nav-link {
        text-decoration: none;
        color: $color_44 !important;
        &::before {
          transform: scaleX(1);
        }
      }
    }
  }
  .nav-item.dropdown {
    .nav-link {
      &::after {
        right: 0;
        top: calc(50% - 6px);
        display: inline-block;
        vertical-align: 0.255em;
        content: "";
        height: 8px;
        width: 8px;
        border: 2px solid;
        border-left: 0;
        border-top: 0;
        border-color: $border-color_1;
        border-radius: 2px;
        transform: rotate(45deg);
        transition: 0s;
      }
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: $color_42;
        &:hover {
          color: $color_44;
        }
      }
      .active {
        .nav-link {
          color: $color_44;
          &::before {
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .navbar-expand-xl {
    .navbar-nav {
      .nav-link {
        padding: 15px;
      }
    }
  }
}
.banner {
  padding: 90px 0;
  z-index: 5;
  .block {
    h1 {
      font-size: 52px;
    }
    .h1 {
      font-size: 52px;
    }
  }
  .has-shapes {
    z-index: -1;
    .shape {
      top: 0;
      height: 100%;
    }
    .shape-left {
      left: 0;
      top: auto;
      bottom: -30px;
    }
    .shape-right {
      right: 0;
    }
  }
}
.about-section {
  z-index: 5;
  .has-shapes {
    z-index: -1;
    .shape-left {
      left: 0;
      bottom: 0;
    }
    .shape-right {
      right: 0;
      top: 0;
    }
  }
}
.has-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  .shape {
    z-index: 9;
    position: absolute;
  }
}
.homepage_tab {
  z-index: 5;
  .payment_info_tab {
    .active {
      color: $color_1 !important;
    }
  }
  .content-block {
    opacity: 0;
    transition: 0.3s;
    position: relative;
    left: -25px;
  }
  .image-block {
    opacity: 0;
    transition: 0.3s;
    position: relative;
    right: -25px;
  }
  .show {
    .content-block {
      opacity: 1;
      left: 0;
    }
    .image-block {
      opacity: 1;
      right: 0;
    }
  }
  > .container {
    position: relative;
    z-index: 10;
    &::after {
      position: absolute;
      content: "";
      height: 65%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: $background-color_56;
      z-index: -2;
      border-radius: 18px;
    }
  }
  .has-shapes {
    z-index: -1;
    .shape-left {
      top: 0;
      left: 0;
    }
    .shape-right {
      top: 0;
      right: 0;
    }
  }
}
.page-header {
  position: relative;
  overflow: hidden;
  padding: 80px 0;
  z-index: 5;
  .has-shapes {
    z-index: -1;
    .shape {
      height: 170%;
    }
    .shape-left {
      left: 50px;
      top: -37%;
      transform: rotate(-35deg);
    }
    .shape-right {
      right: 0;
      bottom: 0;
    }
  }
  .breadcrumbs {
    color: $color_46;
    a {
      color: $color_46;
      &:hover {
        color: $color_44;
      }
    }
    li {
      &:last-child {
        a {
          pointer-events: none;
          opacity: 0.6;
        }
      }
    }
  }
}
.testimonials {
  position: relative;
  z-index: 5;
  .has-shapes {
    z-index: -1;
    .shape-left {
      left: 0;
      bottom: 0;
    }
    .shape-right {
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }
}
.accordion-border-bottom {
  .accordion-item {
    &:not(:last-child) {
      border-bottom: 1px solid #d6d6d6;
      padding-bottom: 20px;
      margin-bottom: 20px;
      .content {
        padding-bottom: 30px;
      }
    }
  }
}

.AdminArea{

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  .selectCategory{
    width: fit-content;
    margin: 0 auto;
    .select{
      width: 100%;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 10px 15px;
      font-size: 15px;
      font-weight: 500;
      color: $color_42;
      &:focus{
        border-color: $color_44;
      }
    }
  }
  .dropdown{
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    input[type="file"] {
      width: 100%;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 10px 15px;
      font-size: 15px;
      font-weight: 500;
      color: $color_42;
      &:focus{
        border-color: $color_44;
      }
      
    }
    
  }
  
  .newCatt{
    text-align: center;
  }

}
.centerButt {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .btn{

    margin-left: 10px;
    margin-right: 10px;
  }
}

.TitleLogin{
  text-align: center;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 700;
  color: $color_17;
  color: $color_17;
  margin-bottom: 20px;
  margin-top: 30px;
  font-weight: 700;

}
.ck-editor__editable_inline {
  min-height: 250px;
  min-width: 50%;
}
.core-value {
  .has-shapes {
    z-index: 2;
    pointer-events: none;
  }
  .shape-1 {
    right: -14px;
    top: -25px;
  }
  .shape-2 {
    left: -30px;
    bottom: -20px;
  }
}
.has-video-popup {
  .video-play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
    &:hover {
      transform: translate(-50%, -50%) scale(1.05);
    }
    svg {
      height: 90px;
      width: 90px;
    }
  }
}
.loan-steps {
  .count {
    height: 70px;
    width: 70px;
    text-align: center;
    line-height: 70px;
    margin: 15px auto 20px;
    font-size: 24px;
    font-weight: 700;
    border-radius: 50%;
    color: $color_1;
    position: relative;
    font-family: $font-family_4;
    &::after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      border: 6px solid rgba(239, 255, 238, 0.5);
      top: 0;
      left: 0;
      border-radius: 50%;
    }
  }
  .step-item {
    margin-top: 30px;
    position: relative;
    z-index: 1;
    &::before {
      position: absolute;
      content: "";
      height: 1px;
      width: 100%;
      border: 1px dashed $color_43;
      top: 50px;
      left: 50%;
      z-index: -1;
    }
    &:nth-child(3) {
      &::before {
        display: none;
      }
    }
    &:nth-child(6) {
      &::before {
        display: none;
      }
    }
    &:nth-child(9) {
      &::before {
        display: none;
      }
    }
    &:last-child {
      &::before {
        display: none;
      }
    }
    .count {
      background-color: $background-color_45;
    }
  }
}
.icon-box-item {
  z-index: 3;
  .block {
    padding: 35px 27px;
    box-shadow: 0px 25px 65px 0px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
  }
  .icon {
    display: inline-block;
    height: 90px;
    width: 90px;
    line-height: 90px;
    font-size: 32px;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
    color: $color_1;
    background-color: $background-color_45;
  }
}
.icon-lg {
  height: unset !important;
  width: unset !important;
  line-height: 1 !important;
  i {
    font-size: 40px;
  }
}
.rounded-number {
  font-size: 24px !important;
  height: 70px !important;
  width: 70px !important;
  border-radius: 8px !important;
  font-weight: 800;
  line-height: 70px !important;
}
.form-group {
  position: relative;
  margin-top: 8px;
  label {
    position: absolute;
    background-color: $background-color_1;
    top: -13px;
    left: 12px;
    padding: 0 9px;
  }
}
.contact-info {
  padding-left: 50px;
  .block {
    .icon {
      margin-right: 15px;
      font-size: 18px;
      line-height: 0;
      position: relative;
      top: 5px;
    }
    .social-links {
      li {
        margin-right: 15px;
      }
      a {
        font-size: 22px;
      }
    }
  }
}
.newsletter-form {
  margin-top: -35px;
  input {
    border-color: $border-color_43 !important;
    border-radius: 8px;
    padding: 0 15px;
  }
  .input-group-text {
    border-color: $border-color_43 !important;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  button {
    color: $color_1;
    border-radius: 0px;
    padding: 19px;
  }
}
.footer-widget {
  ul {
    li {
      a {
        color: $color_10;
        &:hover {
          color: $color_44;
          text-decoration: underline !important;
        }
      }
    }
  }
}
.social-icons {
  a {
    display: inline-block;
    height: 45px;
    width: 45px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
}
#TableOfContents {
  ul {
    margin-bottom: 0;
    margin-top: 15px;
    padding-left: 0;
    ul {
      margin-top: 0;
    }
    li {
      list-style-type: none;
      padding-left: 20px;
      position: relative;
      color: $color_42;
      &::before {
        position: absolute;
        content: "";
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: $color_43;
        left: 3px;
        top: 15px;
      }
    }
  }
  a {
    display: block;
    padding: 8px 0;
    color: $color_42;
    font-weight: 400;
    transition: 0.2s ease;
  }
  a.active {
    color: $color_44;
  }
}
.has-line-end {
  display: inline-block;
  position: relative;
  &::before {
    position: absolute;
    left: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
    content: "";
    height: 1px;
    width: 30px;
    background-color: $background-color_35;
  }
}
.teams {
  .position-relative {
    z-index: 1;
  }
}
.post-slider {
  overflow: hidden;
  &:hover {
    .prevArrow {
      left: 30px;
    }
    .nextArrow {
      right: 30px;
    }
  }
}
.image-fallback {
  position: relative;
  font-size: 0;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &::first-letter {
      font-size: 72px;
      color: $color_10;
    }
  }
}
.slider-sm {
  .slick-arrow {
    height: 35px;
    width: 35px;
    line-height: 35px;
  }
}
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 50%;
  background: rgba(81, 181, 109, 0.5);
  color: $color_1;
  border: 0;
  &:focus {
    outline: 0;
  }
  &:hover {
    background: $color_43;
  }
}
.prevArrow {
  left: -60px;
}
.nextArrow {
  right: -60px;
}
.widget {
  margin-bottom: 30px;
  .widget-title {
    margin-bottom: 15px;
  }
}
.widget.widget-categories {
  li {
    a {
      display: block;
      background: #fbfbfb;
      padding: 10px 18px;
      color: $color_10;
      margin-bottom: 5px;
      border-radius: 8px;
      text-decoration: none;
      &:hover {
        background-color: $background-color_45;
        color: $color_1;
      }
    }
  }
}
.widget.widget-tags {
  li {
    a {
      display: block;
      background: #fbfbfb;
      padding: 10px 18px;
      color: $color_10;
      margin-bottom: 5px;
      border-radius: 8px;
      text-decoration: none;
      display: inline-block;
      margin-bottom: 8px;
      &:hover {
        background-color: $background-color_45;
        color: $color_1;
      }
    }
  }
}
.widget-post {
  .widget-post-image {
    width: 80px;
    height: 80px;
    img {
      height: 100%;
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
    }
  }
}
.applyLoanModal {
  .modal-content {
    padding: 10px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .root {
    scroll-behavior: smooth;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
  .h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  .h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  .h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  .h4 {
    font-size: 1.5rem;
  }
  legend {
    font-size: 1.5rem;
  }
  .display-1 {
    font-size: 5rem;
  }
  .display-2 {
    font-size: 4.5rem;
  }
  .display-3 {
    font-size: 4rem;
  }
  .display-4 {
    font-size: 3.5rem;
  }
  .display-5 {
    font-size: 3rem;
  }
  .display-6 {
    font-size: 2.5rem;
  }
  .container-xl {
    max-width: 1140px;
  }
  .container-lg {
    max-width: 1140px;
  }
  .container-md {
    max-width: 1140px;
  }
  .container-sm {
    max-width: 1140px;
  }
  .container {
    max-width: 1140px;
  }
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .row-cols-xl-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .row-cols-xl-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .row-cols-xl-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .row-cols-xl-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .row-cols-xl-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .row-cols-xl-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
    .navbar-nav {
      flex-direction: row;
      .dropdown-menu {
        position: absolute;
      }
      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-toggler {
      display: none;
    }
  }
  .modal-xl {
    max-width: 1140px;
  }
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .container-sm {
    max-width: 540px;
  }
  .container {
    max-width: 540px;
  }
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .row-cols-sm-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .row-cols-sm-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .row-cols-sm-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .row-cols-sm-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .row-cols-sm-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .row-cols-sm-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
    .navbar-nav {
      flex-direction: row;
      .dropdown-menu {
        position: absolute;
      }
      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-toggler {
      display: none;
    }
  }
  .card-group {
    display: flex;
    flex-flow: row wrap;
    > .card {
      flex: 1 0 0%;
      margin-bottom: 0;
      + {
        .card {
          margin-left: 0;
          border-left: 0;
        }
      }
      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        .card-img-top {
          border-top-right-radius: 0;
        }
        .card-header {
          border-top-right-radius: 0;
        }
        .card-img-bottom {
          border-bottom-right-radius: 0;
        }
        .card-footer {
          border-bottom-right-radius: 0;
        }
      }
      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        .card-img-top {
          border-top-left-radius: 0;
        }
        .card-header {
          border-top-left-radius: 0;
        }
        .card-img-bottom {
          border-bottom-left-radius: 0;
        }
        .card-footer {
          border-bottom-left-radius: 0;
        }
      }
    }
  }
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .container-md {
    max-width: 720px;
  }
  .container-sm {
    max-width: 720px;
  }
  .container {
    max-width: 720px;
  }
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .row-cols-md-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .row-cols-md-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .row-cols-md-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .row-cols-md-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .row-cols-md-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .row-cols-md-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
    .navbar-nav {
      flex-direction: row;
      .dropdown-menu {
        position: absolute;
      }
      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-toggler {
      display: none;
    }
  }
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .container-lg {
    max-width: 960px;
  }
  .container-md {
    max-width: 960px;
  }
  .container-sm {
    max-width: 960px;
  }
  .container {
    max-width: 960px;
  }
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .row-cols-lg-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .row-cols-lg-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .row-cols-lg-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .row-cols-lg-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .row-cols-lg-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .row-cols-lg-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
    .navbar-nav {
      flex-direction: row;
      .dropdown-menu {
        position: absolute;
      }
      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-toggler {
      display: none;
    }
  }
  .modal-lg {
    max-width: 800px;
  }
  .modal-xl {
    max-width: 800px;
  }
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .container-xxl {
    max-width: 1320px;
  }
  .container-xl {
    max-width: 1320px;
  }
  .container-lg {
    max-width: 1320px;
  }
  .container-md {
    max-width: 1320px;
  }
  .container-sm {
    max-width: 1320px;
  }
  .container {
    max-width: 1320px;
  }
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .row-cols-xxl-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .row-cols-xxl-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .row-cols-xxl-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .row-cols-xxl-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .row-cols-xxl-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .row-cols-xxl-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
    .navbar-nav {
      flex-direction: row;
      .dropdown-menu {
        position: absolute;
      }
      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-toggler {
      display: none;
    }
  }
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-header {
      border-radius: 0;
    }
    .modal-body {
      overflow-y: auto;
    }
    .modal-footer {
      border-radius: 0;
    }
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-header {
      border-radius: 0;
    }
    .modal-body {
      overflow-y: auto;
    }
    .modal-footer {
      border-radius: 0;
    }
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-header {
      border-radius: 0;
    }
    .modal-body {
      overflow-y: auto;
    }
    .modal-footer {
      border-radius: 0;
    }
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-header {
      border-radius: 0;
    }
    .modal-body {
      overflow-y: auto;
    }
    .modal-footer {
      border-radius: 0;
    }
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-header {
      border-radius: 0;
    }
    .modal-body {
      overflow-y: auto;
    }
    .modal-footer {
      border-radius: 0;
    }
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
    &::-webkit-file-upload-button {
      -webkit-transition: none;
      transition: none;
      -webkit-transition: none;
      transition: none;
    }
    &::file-selector-button {
      transition: none;
    }
  }
  .form-select {
    transition: none;
  }
  .form-switch {
    .form-check-input {
      transition: none;
    }
  }
  .form-range {
    &::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none;
    }
    &::-moz-range-thumb {
      -moz-transition: none;
      transition: none;
    }
  }
  .form-floating {
    > label {
      transition: none;
    }
  }
  .btn {
    transition: none;
  }
  .fade {
    transition: none;
  }
  .collapsing {
    transition: none;
  }
  .nav-link {
    transition: none;
  }
  .navbar-toggler {
    transition: none;
  }
  .accordion-button {
    transition: none;
    &::after {
      transition: none;
    }
  }
  .page-link {
    transition: none;
  }
  .modal.fade {
    .modal-dialog {
      transition: none;
    }
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 38px;
  }
  .h1 {
    font-size: 38px;
  }
  h2 {
    font-size: 30px;
  }
  .h2 {
    font-size: 30px;
  }
  .banner {
    .block {
      h1 {
        font-size: 38px;
      }
      .h1 {
        font-size: 38px;
      }
    }
  }
  .service-item {
    border: 1px solid #f4f4f4;
    border-bottom: 0;
  }
  .homepage_tab {
    overflow: hidden;
  }
  .has-video-popup {
    .video-play-btn {
      svg {
        height: 60px;
        width: 60px;
        margin-left: -35px;
      }
    }
  }
  .loan-steps {
    .step-item {
      &::before {
        display: none !important;
      }
    }
  }
}
@media (min-width: 1199px) {
  .navigation {
    .dropdown-menu {
      display: block;
      opacity: 0;
      visibility: hidden;
      min-width: 200px;
      transition: 0.2s;
      left: 50%;
      border-radius: 8px;
      transform: translate(-50%, 10px) scaleX(1.2);
      transform-origin: top;
      li {
        opacity: 0;
        transition: 0.3s;
        transition-delay: 0.1s;
      }
    }
    .dropdown {
      &:hover {
        .dropdown-menu {
          opacity: 1;
          visibility: visible;
          box-shadow: 0 10px 30px rgba(22, 28, 45, 0.1);
          transform: translate(-50%, -6px) scale(1);
          li {
            opacity: 1;
          }
        }
      }
    }
    .nav-item.dropdown {
      .nav-link {
        margin-right: 16px;
        &::after {
          position: absolute;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .navigation {
    .dropdown-menu {
      text-align: center;
      border: 1px solid rgba(81, 181, 109, 0.2);
    }
    .nav-item {
      .nav-link {
        &::before {
          display: none;
        }
      }
    }
  }
  .banner {
    .block {
      h1 {
        font-size: 46px;
      }
      .h1 {
        font-size: 46px;
      }
    }
  }
  .service-item {
    &:nth-child(2) {
      border-left: 1px solid #f4f4f4;
    }
    &:nth-child(3) {
      border-left: 1px solid #f4f4f4;
    }
    &:nth-child(5) {
      border-left: 1px solid #f4f4f4;
      border-bottom: 1px solid #f4f4f4;
    }
    &:nth-child(6) {
      border-bottom: 1px solid #f4f4f4;
    }
  }
  .loan-steps {
    .step-item {
      &:nth-child(2) {
        &::before {
          display: none;
        }
      }
      &:nth-child(3) {
        &::before {
          display: none;
        }
      }
      &:nth-child(6) {
        &::before {
          display: none;
          display: block;
        }
      }
      &:nth-child(1) {
        &::before {
          display: block;
        }
      }
      &:nth-child(9) {
        &::before {
          display: block;
        }
      }
    }
  }
  .contact-info {
    padding-left: 0;
  }
  .newsletter-form {
    margin-top: 0;
  }
}
@media (max-width: 1200px) {
  .navigation {
    .nav-item {
      .nav-link {
        padding: 10px 0 !important;
      }
    }
  }
}
@media (min-width: 991px) {
  .service-item {
    &:nth-child(2) {
      border-left: 1px solid #f4f4f4;
    }
    &:nth-child(4) {
      border-left: 1px solid #f4f4f4;
      border-bottom: 1px solid #f4f4f4;
    }
    &:nth-child(5) {
      border-bottom: 1px solid #f4f4f4;
    }
    &:nth-child(6) {
      border-bottom: 1px solid #f4f4f4;
    }
  }
}
@media (max-width: 575px) {
  .slick-arrow {
    display: none;
  }
}
